import React, { useState, useEffect, useRef, useMemo} from 'react';
import NzDateStringToIsoDate, { IsoDateTimeToIsoDate, DayDifference } from '../../helper/DateHelper';
import getDownloadIcon, {getMagnifierIcon, getFilterIcon, getSortingIcon, getDoubleArrowLeftIcon, getDoubleArrowRightIcon, getSingleArrowLeftIcon, getSingleArrowRightIcon, getRefreshIcon, getCalenderIcon, getSpinnerIcon} from '../../helper/SvgHelper';
import useWindowDimensions from '../../helper/WindowDimensionHelper';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from "react-table";
import { matchSorter } from 'match-sorter'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RegistrationResultTable from './RegTestResultTable';
import DownloadReportCatalog from '../report/DownloadReportCatalog';
import api from '../../config/Api';


let priorityFilterList = [];
let statusFilterList = [];
let dueDateFilter = null;

function PriorityColumnFilter({column: { filterValue, setFilter, preFilteredRows, id},}) {
    const priorityOptionRef = useRef(null);
    const [showPriorityFilterOption, setShowPriorityFilterOption] = useState(false);

    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
          options.add(row.values[id])
        });
        return [...options.values()];
    }, [id, preFilteredRows])

    
    const setSelectedFilterOptions = (priority) => {
        if(!Array.isArray(priorityFilterList)) { priorityFilterList = [];}
        
        let index = priorityFilterList.indexOf(priority);
        if (index >= 0) {
            priorityFilterList.splice(index, 1);
            if(priorityFilterList.length === 0) {priorityFilterList = undefined;}
            setFilter(priorityFilterList);
            return;
        }
        priorityFilterList.push(priority);
        setFilter(priorityFilterList);
    }

    const clearFilter = () => {
        priorityFilterList = []; 
        setFilter(undefined);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(priorityOptionRef.current && !priorityOptionRef.current.contains(event.target)) {
                setShowPriorityFilterOption(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    return (
        <div ref={ priorityOptionRef } className="relative inline-block text-left ml-1">
            <div className="cursor-pointer" onClick={ () => { setShowPriorityFilterOption(!showPriorityFilterOption); } }>
                <span>
                    {getFilterIcon(priorityFilterList === undefined ? "" : priorityFilterList.toString())}
                </span>
            </div>
            {showPriorityFilterOption && preFilteredRows.length > 0 &&
            <div onClick={ (e)=>{ e.stopPropagation(); }} className="origin-top-right absolute right-0 mt-2 w-auto p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                {options.map((option, i) => (
                    <div className="flex items-center h-5 px-3 my-2" key={i}>
                        <input checked={Array.isArray(priorityFilterList) && priorityFilterList.indexOf(option) >= 0} type="checkbox" onChange={ () =>{ setSelectedFilterOptions(option); } } className="h-4 w-4 border-gray-300 rounded" /><span className="ml-3 text-gray-700" >{option}</span>
                    </div>
                ))}
                <div className="flex items-end justify-between h-7 px-2 mt-2 border-t border-gray-300">
                    <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none" onClick={ ()=>{ setShowPriorityFilterOption(false);} }>Hide</button>
                    <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none" onClick={ ()=>{ clearFilter();} }>Clear</button>
                </div>
            </div>}
        </div>
    )
}

function StatusColumnFilter({column: { filterValue, setFilter, preFilteredRows, id},}) {
    const statusOptionRef = useRef(null);
    const [showStatusFilterOption, setShowStatusFilterOption] = useState(false);

    const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
          options.add(row.values[id])
        });
        return [...options.values()];
    }, [id, preFilteredRows])

    
    const setSelectedFilterOptions = (status) => {
        if(!Array.isArray(statusFilterList)) { statusFilterList = [];}
        
        let index = statusFilterList.indexOf(status);
        if (index >= 0) {
            statusFilterList.splice(index, 1);
            if(statusFilterList.length === 0) {statusFilterList = undefined;}
            setFilter(statusFilterList);
            return;
        }
        statusFilterList.push(status);
        setFilter(statusFilterList);
    }

    const clearFilter = () => {
        statusFilterList = []; 
        setFilter(undefined);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(statusOptionRef.current && !statusOptionRef.current.contains(event.target)) {
                setShowStatusFilterOption(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    return (
            <div ref={ statusOptionRef } className="relative inline-block text-left">
                <div className="cursor-pointer">
                    <span onClick={ () => setShowStatusFilterOption(!showStatusFilterOption) }>
                        {getFilterIcon(statusFilterList === undefined ? "" : statusFilterList.toString())}
                    </span>
                </div>
                {showStatusFilterOption && preFilteredRows.length > 0 &&
                <div onClick={ (e)=>{ e.stopPropagation(); }} className="origin-top-right absolute right-0 mt-2 p-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    {options.map((option, i) => (
                        <div className="flex items-center h-5 px-3 my-2" key={i}>
                            <input checked={Array.isArray(statusFilterList) && statusFilterList.indexOf(option) >= 0} type="checkbox" onChange={ () => setSelectedFilterOptions(option) } className="h-4 w-4 border-gray-300 rounded" /><span className="ml-3 text-gray-700" >{option}</span>
                        </div>
                    ))}
                    <div className="flex items-end justify-between h-7 px-2 mt-2 border-t border-gray-300">
                        <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none" onClick={ ()=>{ setShowStatusFilterOption(false); }}>Hide</button>
                        <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none" onClick={ ()=>{ clearFilter(); }}>Clear</button>
                    </div>
                </div>
                }
            </div>
    )
}

function DueDateColumnFilter({column: { filterValue, setFilter, preFilteredRows, id},}) {
    const dueDateOptionRef = useRef(null);
    const [showDueDateFilterOption, setShowDueDateFilterOption] = useState(false);

    const customOptions = ['Overdued', 'Scheduled'];
    
    const setSelectedFilterOptions = (status) => {
        dueDateFilter = status;
        setFilter(dueDateFilter);
    }

    const clearFilter = () => {
        dueDateFilter = null; 
        setFilter(undefined);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(dueDateOptionRef.current && !dueDateOptionRef.current.contains(event.target)) {
                setShowDueDateFilterOption(false);
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    
    return (
        <div ref={ dueDateOptionRef } className="relative inline-block text-left ml-1">
            <div className="cursor-pointer">
                <span onClick={ (e) => {setShowDueDateFilterOption(!showDueDateFilterOption); e.stopPropagation(); }}>
                    {getFilterIcon(dueDateFilter)}
                </span>
            </div>
            {showDueDateFilterOption && preFilteredRows.length > 0 &&
             <div onClick={ (e)=>{ e.stopPropagation(); }}  className="origin-top-right absolute right-0 mt-2 p-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                {customOptions.map((option, i) => (
                    <div className="flex items-center h-5 px-3 my-2" key={i}>
                        <input checked={ dueDateFilter === option } type="radio" onChange={ () => setSelectedFilterOptions(option) } className="form-checkbox h-4 w-4 border-gray-300"/><span className="ml-3 text-gray-700">{option}</span>
                    </div>
                ))}
                <div className="flex items-end justify-between h-7 px-2 mt-2 border-t border-gray-300">
                    <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none" onClick={ ()=>{ setShowDueDateFilterOption(false); }}>Hide</button>
                    <button type="button" className="justify-self-end px-1 py-0.5 border border-transparent text-xs rounded-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none" onClick={ ()=>{ clearFilter(); }}>Clear</button>
                </div>
            </div>}
        </div>
    )
}

function filterMultipleOptions(rows, id, filterValue) {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return filterValue.indexOf(rowValue) >= 0
    });
}

function filterDueDateOptions(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id];
        
        return  filterValue === 'Overdued'
                ? NzDateStringToIsoDate(rowValue) < IsoDateTimeToIsoDate(new Date())
                :NzDateStringToIsoDate(rowValue) >= IsoDateTimeToIsoDate(new Date());
    });
}

function filterDateRangeOptions(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id];
        return  NzDateStringToIsoDate(rowValue) <= IsoDateTimeToIsoDate(filterValue[1]) && NzDateStringToIsoDate(rowValue) >= IsoDateTimeToIsoDate(filterValue[0]);
    });
}

function DefaultColumnFilter({column: { filterValue, preFilteredRows, setFilter },}) {
    //const count = preFilteredRows.length
    return (<></>)
}

const SortingIcon = ({columnName, currentColumnName, sortAsc}) => {
    
    return (
        <>
            {getSortingIcon(columnName, currentColumnName, sortAsc)}
        </>
    )
};


function GlobalInputSearchFilter({preGlobalFilteredRows, globalFilter, setGlobalFilter, startDate, endDate, getRegistrationList, setRegistrationList}) {
    const [searchCategory, setSearchCategory] = useState("URegRef");
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false);

    const searchCategoryList = [
        {
            value: 'URegRef',
            text: 'Reg No.'
        },
        {
            value: 'RegKeyFld',
            text: 'Batch No.'
        },
        {
            value: 'JobDescrip',
            text: 'Description'
        },
        {
            value: 'TName',
            text: 'Test Name'
        },
        {
            value: 'TMethod',
            text: 'Method No.'
        },
        {
            value: 'SAMPLE_ID',
            text: 'Sample Id'
        },
    ];

    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => { setGlobalFilter(value || undefined) }, 200);

    const searchRegistrationList = async () => {
        setLoading(true);
        await api.get('/access/search-registrations',{params: {startDate,endDate, searchCategory, searchInput}})
                .then(res => {
                    if(res.data.success) { 
                        setRegistrationList(res.data.data);
                    }else {
                        setRegistrationList(null);
                    };
                })
                .catch(err => {
                    console.log(err.response.data.message)
                });
        setLoading(false);
    }
    
    return (
        <div className="flex fixed right-80 top-28">
            <input 
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                    setSearchInput(e.target.value);
                }} 
                placeholder="Search"
                type="search" 
                className="w-96 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none text-sm"
            />
            <select className="border border-gray-300 px-2 py-1 bg-white text-sm font-medium text-gray-700 focus:outline-none hover:bg-gray-50 cursor-pointer"
                value={ searchCategory }
                onChange={e => {
                    setSearchCategory(e.target.value)
                }}
                >
                {searchCategoryList.map((option, i) => (
                    <option key={i} value={option.value}> {option.text} </option>
                ))}
            </select>
            <button disabled={loading} onClick={() => searchRegistrationList()} type="button" className={`${loading ? 'px-1.5' : 'px-3'} py-2 border border-transparent text-sm leading-4 font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none`}>
                {loading ? <div className="px-1.5">{getSpinnerIcon()}</div> : getMagnifierIcon()}
            </button>
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
} 
fuzzyTextFilterFn.autoRemove = val => !val;

function SortByDate(a, b) {
    if(a === b) {return 0;}
    if(!a || !b) { return !a ? -1 : !b ? 1 : 0; }
    return NzDateStringToIsoDate(a) > NzDateStringToIsoDate(b) ? 1 : -1;
}

function ReactTablePagination ({page,canPreviousPage,canNextPage,pageOptions,pageCount,gotoPage,nextPage,previousPage,pageIndex,pageSize,setPageSize, rows}) {
    const [dynamicPageOffsetNegTwo, setDynamicPageOffsetNegTwo] = useState(2);
    const [dynamicPageOffsetNegOne, setDynamicPageOffsetNegOne] = useState(3);
    const [dynamicPageOffsetNil, setDynamicPageOffsetNil] = useState(4);
    const [dynamicPageOffsetPosOne, setDynamicPageOffsetPosOne] = useState(5);
    const [dynamicPageOffsetPosTwo, setDynamicPageOffsetPosTwo] = useState(6);

    const DoubleArrowLeft = ({gotoPage, pageIndex}) => {
        let pageNum = pageIndex-5 >= 0 ? pageIndex - 5 : 0;
        return (
            <div>
                <button onClick={() => gotoPage(pageNum)} disabled={pageIndex < 4} className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-xl leading-5 font-bold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Previous-five">
                    {getDoubleArrowLeftIcon()}
                </button>
            </div>
        )
    }

    const DoubleArrowRight = ({gotoPage, pageIndex, pageCount}) => {
        let pageNum = pageIndex + 5 <= pageCount - 1 ? pageIndex + 5 : pageCount - 1;
        return (
            <div>
                <button onClick={() => gotoPage(pageNum)} disabled={pageIndex + 4 > pageCount} className="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-xl leading-5 font-bold text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Next-five">
                    {getDoubleArrowRightIcon()}
                </button>
            </div>
        )
    }

    const SingleArrowLeft = ({previousPage, canPreviousPage}) => {
        return (
            <div>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Previous">
                    {getSingleArrowLeftIcon()}
                </button>
            </div>
        )
    }
    const SingleArrowRight = ({nextPage, canNextPage}) => {
        return (
            <div>
                <button onClick={() => nextPage()} disabled={!canNextPage} className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-gray-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Next">
                    {getSingleArrowRightIcon()}
                </button>
            </div>
        )
    }

    useEffect(() => {
        const setPageNumbers = () => {
            if(pageCount <= 7) {
                setDynamicPageOffsetNegTwo(2);
                setDynamicPageOffsetNegOne(3);
                setDynamicPageOffsetNil(4);
                setDynamicPageOffsetPosOne(5);
                setDynamicPageOffsetPosTwo(6);
            } else {
                if(pageIndex + 3 >= pageCount) {
                    setDynamicPageOffsetNegTwo(pageCount - 5);
                    setDynamicPageOffsetNegOne(pageCount - 4);
                    setDynamicPageOffsetNil(pageCount - 3);
                    setDynamicPageOffsetPosOne(pageCount - 2);
                    setDynamicPageOffsetPosTwo(pageCount - 1);
                } 
                else if (pageIndex >= 4) {
                    setDynamicPageOffsetNegTwo(pageIndex -1);
                    setDynamicPageOffsetNegOne(pageIndex);
                    setDynamicPageOffsetNil(pageIndex + 1);
                    setDynamicPageOffsetPosOne(pageIndex + 2);
                    setDynamicPageOffsetPosTwo(pageIndex + 3);
                }
                else {
                    setDynamicPageOffsetNegTwo(2);
                    setDynamicPageOffsetNegOne(3);
                    setDynamicPageOffsetNil(4);
                    setDynamicPageOffsetPosOne(5);
                    setDynamicPageOffsetPosTwo(6);
                }
            }
        }
        setPageNumbers();
    }, [pageIndex]);

    return (
        <nav className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="hidden sm:block">
                <p className="text-gray-500 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium">
                    Showing {rows.length > 0 ? 1 : 0 + pageIndex * pageSize } to { (pageIndex + 1) * pageSize < rows.length ?  (pageIndex + 1) * pageSize : rows.length } of { rows.length } Registrations
                </p>
            </div>

            <div className="flex-1 flex justify-between sm:justify-end">
                <SingleArrowLeft previousPage={previousPage} canPreviousPage={canPreviousPage}/>
                <div>
                    <button onClick={() => gotoPage(0)} value={1} className={`-ml-px ${pageIndex===0 ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:underline`}>
                        1
                    </button>
                </div>
                { (pageIndex + 1) >= 5 && pageCount > 7 && <DoubleArrowLeft gotoPage={gotoPage} pageIndex={pageIndex}/> }
                <div className={`${pageCount > 2 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetNegTwo-1)} value={dynamicPageOffsetNegTwo} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetNegTwo ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetNegTwo }
                    </button>
                </div>
                <div className={`${pageCount > 3 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetNegOne-1)} value={dynamicPageOffsetNegOne} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetNegOne ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetNegOne }
                    </button>
                </div>
                <div className={`${pageCount > 4 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetNil-1)} value={dynamicPageOffsetNil} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetNil ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetNil }
                    </button>
                </div>
                <div className={`${pageCount > 5 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetPosOne-1)} value={dynamicPageOffsetPosOne} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetPosOne ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetPosOne }
                    </button>
                </div>
                <div className={`${pageCount > 6 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(dynamicPageOffsetPosTwo-1)} value={dynamicPageOffsetPosTwo} className={`-ml-px ${(pageIndex + 1)===dynamicPageOffsetPosTwo ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out hover:underline`}>
                        { dynamicPageOffsetPosTwo }
                    </button>
                </div>
                { (pageCount - (pageIndex + 1) > 3) && pageCount > 7 && <DoubleArrowRight gotoPage={gotoPage} pageIndex={pageIndex} pageCount={pageCount} /> }
                <div className={`${pageCount > 1 ? "" : "hidden"}`}>
                    <button onClick={() => gotoPage(pageCount-1)} value={pageCount} className={`-ml-px ${(pageIndex + 1)===pageCount ? "border-blue-300 z-10 outline-none shadow-outline-blue" : "border-gray-300"} relative inline-flex items-center px-4 py-2 border bg-white text-sm leading-5 font-medium text-blue-700 focus:z-10 focus:outline-none active:bg-tertiary active:text-gray-700 transition ease-in-out duration-150 hover:underline`}>
                        { pageCount }
                    </button>
                </div>
                <SingleArrowRight nextPage={nextPage} canNextPage={canNextPage}/>
            </div>
        </nav>
    )
}

// Table UI control Start
const MainDonwnLoadButton = ({setDownloadReportCatalog}) => {
    return (
        <button 
            type="button" 
            className="fixed right-28 top-28 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={(e) => {e.stopPropagation(); setDownloadReportCatalog(true);}}
        >
            Download Report
            {getDownloadIcon()}
        </button>    
    );
}

const RefreshIcon = ({getRegistrationList, setStartDate, setEndDate, setSortColumnName, setSortAsc}) => {

    const[loading, setLoading] = useState(false);

    const refreshRegistrationList = async () => {
        setLoading(true);
        priorityFilterList = [];
        statusFilterList = [];
        dueDateFilter = null;
        setStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1)));
        setEndDate(new Date());
        setSortColumnName(null);
        setSortAsc(null);
        await getRegistrationList();
        setLoading(false);
    }

    return (
        <th className="px-4 pt-5 pb-1 text-center text-gray-500">
            <button className="focus:outline-none" disabled={loading} onClick={()=>refreshRegistrationList()}>
                { loading ? getSpinnerIcon() : getRefreshIcon() }
            </button>
        </th>
    );
}

const ReactTable = ({ columns, data, getRegistrationList, setRegistrationList }) => {
    // const filterTypes = useMemo(() => ({
    //     fuzzyText: fuzzyTextFilterFn,

    //     text: (rows, id, filterValue) => {
    //         return rows.filter(row => {
    //             const rowValue = row.values[id]
    //             return rowValue !== undefined
    //                     ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
    //                     : true
    //         })
    //     },
    // }), []);
    
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [showResult, setShowResult] = useState(false);
    const [showDownloadReportCatalog, setDownloadReportCatalog] = useState(false);
    const [regResultList, setRegResultList] = useState(false);
    const [isIANZ, setIsIANZ] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const { height, width } = useWindowDimensions();
    let trHeight = 53;
    let nonTrHeight = 329;

    const [sortColumnName, setSortColumnName] = useState(null);
    const [sortAsc, setSortAsc] = useState(null);

    class StartDatePicker extends React.Component {
        render() {
            return (<button onClick={this.props.onClick} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none">
                        {this.props.value}
                        {getCalenderIcon()}
                    </button>);
        }
    };
    class EndDatePicker extends React.Component {
        render() {
            return (<button onClick={this.props.onClick} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none">
                        {this.props.value}
                        {getCalenderIcon()}
                    </button>);
        }
    };

    const defaultColumn = useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),[]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        loading,
        setFilter,

        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns:['RegTID'],
                pageIndex: 0
            },
            defaultColumn,
            //filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );


    const getRegResultList = async (regTID) => {
        if(regTID) {
            await api.get('/access/registration-results', { params: { regTID: regTID } })
            .then(res => {
                if(res.data.success) { 
                    setRegResultList(res.data.data);
                    if(res.data.data.length > 0 && res.data.data[0].PrtFile.includes("IANZ")) { setIsIANZ(true); }
                    else {setIsIANZ(false);}
                    setShowResult(true);
                }else {
                    setRegResultList(null);
                    setShowResult(false);
                };
            })
            .catch(err => {
                console.log(err.response.data.message)
            });
        }
        
    }

    const changeSortingColumn = (column) => {
        
        if(!column.canSort) { return; }

        if(column.Header === sortColumnName) {
            if(sortAsc === true) { 
                setSortAsc(false);
                return;
            }
            if(sortAsc === false) { 
                setSortAsc(null);
                return;
            }
            if(sortAsc === null) { 
                setSortAsc(true);
                return;
            }
        }
        
        setSortColumnName(column.Header);
        setSortAsc(true);
    }

    useEffect(() => {
        setFilter('RegDate', [startDate, endDate]);
        Math.floor((height - nonTrHeight)/trHeight) > 1 ? setPageSize(Math.floor((height - nonTrHeight)/trHeight)) : setPageSize(1);
    },[startDate, endDate, height]);

    return (
        <>
            <div className="shadow border-b border-gray-200 rounded-lg">
                <table className="w-full divide-y divide-gray-200" {...getTableProps()}>
                    <thead className="bg-gray-100">
                        <tr className="h-12">
                            <th>
                                <div className="fixed flex items-center left-96 top-28">
                                    <div className="pr-4 text-gray-800">Registered Date from </div>
                                    <DatePicker selected={startDate} onChange={date => {setStartDate(date)}} customInput={<StartDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                    <p className="px-4 text-gray-800"> to </p>
                                    <DatePicker selected={endDate} onChange={date => {setEndDate(date)}} customInput={<EndDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                </div>
                            </th>
                            <th>
                                <GlobalInputSearchFilter 
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    setRegistrationList={setRegistrationList}
                                    startDate={startDate}
                                    endDate={endDate}
                                    getRegistrationList={getRegistrationList}
                                />
                            </th>
                            <th>
                                <MainDonwnLoadButton setDownloadReportCatalog={setDownloadReportCatalog}/>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th 
                                        className="px-4 pt-6 pb-3 text-left text-xs font-medium text-gray-500 uppercase tracking-tight"
                                        key={column.Header}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        
                                    >
                                        <div 
                                            className="flex justify-center items-center"
                                            onClick={() => { changeSortingColumn(column); }}
                                        >
                                            <div className="inline-block mr-1.5">{column.render('Header')}</div>
                                            {!column.disableSortBy && <SortingIcon columnName={column.Header} currentColumnName={sortColumnName} sortAsc={sortAsc}/>}
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    </th>
                                ))}
                                <RefreshIcon getRegistrationList={getRegistrationList} setStartDate={setStartDate} setEndDate={setEndDate} setSortColumnName={setSortColumnName} setSortAsc={setSortAsc}/>
                            </tr>
                        ))}
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200" {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr className="hover:bg-gray-100 cursor-pointer" {...row.getRowProps()} onClick={()=>{getRegResultList(row.values.RegTID); setSelectedRow(row.values);}}>
                                    {row.cells.map(cell => {
                                        return (
                                                cell.column.Header === "Samples" ?
                                                <td className="px-4 py-4 text-center whitespace-nowrap" key={ i } {...cell.getCellProps()}>
                                                    <div className={`${width > 1920 ? 'text-center' : ''} text-sm text-gray-900`}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                </td>
                                                :
                                                cell.column.Header === "Priority" ?
                                                <td className="px-4 py-4 whitespace-nowrap" key={ i } {...cell.getCellProps()}>
                                                    <div className={`${cell.value === "Urgent" ? "bg-red-100 rounded-md" : cell.value === "Priority" ? "bg-yellow-100 rounded-md" : ''}`}>
                                                        <div className={`${cell.value === "Urgent" ? "text-red-800" : cell.value === "Priority" ? "text-yellow-700" : ''} text-center text-sm`}>
                                                            {cell.render('Cell')}
                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                cell.column.Header === "Status" ?
                                                <td className="px-4 py-4 whitespace-nowrap" key={ i } {...cell.getCellProps()}>
                                                    <div className={`${cell.value === "Complete" ? "bg-green-100 rounded-md" : ''}`}>
                                                        <div className={`${cell.value === "Complete" ? "text-green-800" : ''} text-center text-sm`}>
                                                            {cell.render('Cell')}
                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                cell.column.Header === "Due Date" ?
                                                <td className="px-4 py-4 whitespace-nowrap" key={ i } {...cell.getCellProps()}>
                                                    <div className={`${DayDifference(IsoDateTimeToIsoDate(new Date()), NzDateStringToIsoDate(cell.value)) < 0 ? '' : DayDifference(IsoDateTimeToIsoDate(new Date()), NzDateStringToIsoDate(cell.value)) <= 1 ? "bg-red-100 rounded-md" : DayDifference(IsoDateTimeToIsoDate(new Date()), NzDateStringToIsoDate(cell.value)) <= 3 ? "bg-yellow-100 rounded-md" : ''}`}>
                                                        <div className={`${DayDifference(IsoDateTimeToIsoDate(new Date()), NzDateStringToIsoDate(cell.value)) < 0 ? '' : DayDifference(IsoDateTimeToIsoDate(new Date()), NzDateStringToIsoDate(cell.value)) <= 1 ? "text-red-800" : DayDifference(IsoDateTimeToIsoDate(new Date()), NzDateStringToIsoDate(cell.value)) <= 3 ? "text-yellow-700" : ''} text-center text-sm`}>
                                                            {cell.render('Cell')}
                                                        </div>
                                                    </div>
                                                </td>
                                                :
                                                <td className="px-4 py-4 max-w-362 truncate whitespace-nowrap" key={ i } {...cell.getCellProps()}>
                                                    <div className={`${width > 1920 ? 'text-center' : ''} text-sm text-gray-900`}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                </td>
                                                )
                                    })}
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" onClick={(e) => {e.stopPropagation(); setSelectedRow(row.values); setDownloadReportCatalog(true); }}>
                                        <button className="text-indigo-600 hover:text-indigo-900">Download</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <ReactTablePagination 
                    page={page} 
                    canPreviousPage={canPreviousPage} 
                    canNextPage={canNextPage} 
                    pageOptions={pageOptions} 
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    pageIndex={state.pageIndex}
                    pageSize={state.pageSize}
                    rows = {rows}
                />
            </div>
            {showResult && <RegistrationResultTable regResultList={regResultList} getRegResultList={getRegResultList} setShowResult={setShowResult} isIANZ={isIANZ} selectedRow={selectedRow} setSelectedRow={setSelectedRow} setDownloadReportCatalog={setDownloadReportCatalog}/>}
            {showDownloadReportCatalog && <DownloadReportCatalog selectedRow={selectedRow} registrationList={data} setSelectedRow={setSelectedRow} setDownloadReportCatalog={setDownloadReportCatalog} /> }
        </>
    );
};

const RegistrationTable = () => {
    const [registrationList, setRegistrationList] = useState(null);
    const columns = [
        {
            Header: 'RegTID',
            accessor: 'RegTID'
        },
        {
            Header: 'Reg No.',
            accessor: 'URegRef'
            // sortDescFirst: true
        },
        {
            Header: 'Batch No.',
            accessor: 'RegKeyFld',
            disableSortBy: true
        },
        {
            Header: 'Description',
            accessor: 'JobDescrip',
            disableSortBy: true
        },
        {
            Header: 'Samples',
            accessor: 'NoSamp',
            disableSortBy: true
        },
        {
            Header: 'Priority',
            accessor: 'Priority',
            Filter: PriorityColumnFilter,
            filter: filterMultipleOptions,
            disableSortBy: true
        },
        {
            Header: 'Status',
            accessor: 'StatCd',
            Filter: StatusColumnFilter,
            filter: filterMultipleOptions,
            disableSortBy: true
        },
        {
            Header: 'Reg Date',
            accessor: 'RegDate',
            filter: filterDateRangeOptions,
            sortMethod: (a, b) => {
                SortByDate(a, b)
            }
        },
        {
            Header: 'Due Date',
            accessor: 'DueDate',
            Filter: DueDateColumnFilter,
            filter: filterDueDateOptions,
            sortMethod: (a, b) => {
                SortByDate(a, b)
            }
        },
        {
            Header: 'Complete Date',
            accessor: 'CompleteDate',
            sortMethod: (a, b) => {
                SortByDate(a, b)
            }
        },
        {
            Header: 'Report Date',
            accessor: 'EMAILED_DATE',
            sortMethod: (a, b) => {
                SortByDate(a, b)
            }
        }
    ];

    const getRegistrationList = async () => {
        await api.get('/access/registrations')
                .then(res => {
                    if(res.data.success) { 
                        setRegistrationList(res.data.data);
                    }else {
                        setRegistrationList(null);
                    };
                })
                .catch(err => {
                    console.log(err.response.data.message)
                });
    }

    useEffect(() => {
        if (registrationList === null) { getRegistrationList(); }
    }, [registrationList]);

    return (
        <div>
            <div className="hidden sm:block">
                <div className="w-11/12 mx-auto">
                    <div className="flex flex-col mt-2">
                        {registrationList && <ReactTable columns={columns} data={registrationList} getRegistrationList={getRegistrationList} setRegistrationList={setRegistrationList} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegistrationTable;



