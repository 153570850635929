import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EmailFormatValidator from '../../helper/ValidationHelper';
import api from '../../config/Api';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon } from '../../helper/SvgHelper';

const AccountTab = () => {

    const [newUser, setNewUser] = useState(true);

    const [userList, setUserList] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const [responseStatus, setResponsStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPromtMsg, setShowPromtMsg] = useState(false);


    const [userName, setUserName] = useState(null);
    const [userNameErrorMsg, setuserNameErrorMsg] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [firstNameErrorMsg, setFirstNameErrorMsg] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [lastNameErrorMsg, setLastNameErrorMsg] = useState(null);
    const [email, setEmail] = useState(null);
    const [emailErrorMsg, setEmailErrorMsg] = useState(null);
    const [initial, setInitial] = useState(null);
    const [department, setDepartment] = useState(null);
    const [position, setPosition] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [accountStatusErrorMsg, setAccountStatusErrorMsg] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [accountTypeErrorMsg, setAccountTypeErrorMsg] = useState(null);
    const [privileges, setPrivileges] = useState(null);

    const userNameInputValidation = (input) => {
        setUserName(input);
        if(!input) {
            setuserNameErrorMsg('* Username is required');
            return;
        }
        if(input.length >= 50) {
            setuserNameErrorMsg('* Username should have less than 50 characters');
            return;
        }
        setuserNameErrorMsg(null);
    }

    const firstNameInputValidation = (input) => {
        setFirstName(input);
        if(!input) {
            setFirstNameErrorMsg('* First name is required');
            return;
        }
        if(input.length >= 50) {
            setFirstNameErrorMsg('* First name should have less than 50 characters');
            return;
        }
        setFirstNameErrorMsg(null);
    }

    const lastNameInputValidation = (input) => {
        setLastName(input);
        if(!input) {
            setLastNameErrorMsg('* Last name is required');
            return;
        }
        if(input.length >= 50) {
            setLastNameErrorMsg('* Last name should have less than 50 characters');
            return;
        }
        setLastNameErrorMsg(null);
    }

    const emailInputValidation = (input) => {
        setEmail(input);
        if(!input) {
            setEmailErrorMsg('* Email is required');
            return;
        }
        if(!EmailFormatValidator(input)) {
            setEmailErrorMsg('* Email is in wrong format');
            return;
        }
        setEmailErrorMsg(null);
    }

    const accountStatusValidation = (input) => {
        setAccountStatus(input);
        if(!input) {
            setAccountStatusErrorMsg('* Account Status is required');
            return;
        }
        setAccountStatusErrorMsg(null);
    }

    const accountTypeValidation = (input) => {
        setAccountType(input);
        if(!input) {
            setAccountTypeErrorMsg('* Account Type is required');
            return;
        }
        setAccountTypeErrorMsg(null);
    }

    const editUserPrivileges = (input) => {
        let tempPrivileges = privileges;
        if(!tempPrivileges) {
            setPrivileges(input);
            return;
        }
        if(privileges.includes(input)) {
            if(tempPrivileges === input) {
                tempPrivileges = "";
            }
            else if(tempPrivileges.indexOf(input) === 0) {
                tempPrivileges = tempPrivileges.replace(input + ',',"");
            }
            else {
                tempPrivileges = tempPrivileges.replace(','+ input,"");
            }
            setPrivileges(tempPrivileges);
            return;
        }
        tempPrivileges = tempPrivileges + ','+ input;
        setPrivileges(tempPrivileges);
    }

    const clearStateBeforeCreateUser =() => {
        setSelectedUser(null);
        setUserName(null);
        setEmail(null);
        setFirstName(null);
        setLastName(null);
        setInitial(null);
        setDepartment(null);
        setPosition(null);
        setAccountType(null);
        setAccountStatus(null);
        setPrivileges(null);
        setNewUser(true);

        setAccountTypeErrorMsg(null);
        setAccountStatusErrorMsg(null);
        setEmailErrorMsg(null);
        setLastNameErrorMsg(null);
        setFirstNameErrorMsg(null);
        setuserNameErrorMsg(null);

        setResponsStatus(null);
        setResponseMsg(null);
        setShowPromtMsg(false);
    }


    const getUserList = async () => {
        setShowPromtMsg(false);
        await api.get('/access/all-users')
        .then(res => {
            if(res.data.success) { 
                setUserList(res.data.data);
            };
        })
        .catch(err => {
            setResponseMsg('Something went wrong, please contact CAIQTest for support.');
            setResponsStatus(false);
            setShowPromtMsg(true);
        });
    }

    const getUserDetails = async (userId) => {
        if(!userId) {
            clearStateBeforeCreateUser();
            return
        }

        setShowPromtMsg(false);
        setNewUser(false);
        setSelectedUser(userId);
        await api.get('/access/user-details',{ params: { userId: userId } })
        .then(res => {
            if(res.data.success) { 
                setUserName(res.data.data.username);
                setEmail(res.data.data.email);
                setFirstName(res.data.data.firstName);
                setLastName(res.data.data.lastName);
                setInitial(res.data.data.initial);
                setDepartment(res.data.data.department);
                setPosition(res.data.data.position);
                setAccountType(res.data.data.accountType);
                setAccountStatus(res.data.data.active);
                setPrivileges(res.data.data.privileges.toString());
            };
        })
        .catch(err => {
            if (err.response.status === 403) {
                setResponseMsg('Request has been denied.');
            }
            else {
                setResponseMsg('Something went wrong, please contact CAIQTest for support.');
            }
            setResponsStatus(false);
            setShowPromtMsg(true);
        });
    }

    const saveUserDetails = async () => {
        if((!userName || userNameErrorMsg) || 
            (!firstName || firstNameErrorMsg) || 
            (!lastName || lastNameErrorMsg) || 
            (!email || emailErrorMsg) ||
            (!accountType || accountTypeErrorMsg)) {
            userNameInputValidation(userName);
            firstNameInputValidation(firstName);
            lastNameInputValidation(lastName);
            emailInputValidation(email);
            accountTypeValidation(accountType);
            return;
        }
        
        if(!newUser) { 
            accountStatusValidation(accountStatus); 
            if(!accountStatus || accountStatusErrorMsg) {
                return;
            }
        }

        setShowPromtMsg(false);
        setLoading(true);
        
        if(newUser) {
            await api.post('/access/create-user', { userName, email, firstName, lastName, initial, department, position, accountType, privileges })
            .then(res => {
                if(res.data.success) { 
                    clearStateBeforeCreateUser();
                    setUserList(res.data.data);
                    setResponseMsg(res.data.message);
                    setResponsStatus(true);
                };
            })
            .catch(err => {
                if (err && err.response && err.response.status && err.response.status === 400) {
                    if(err.response.data.message) {
                        if(err.response.data.message.email) {
                            setResponseMsg(err.response.data.message.email[0]);
                        }
                        else if (err.response.data.message.userName) {
                            setResponseMsg(err.response.data.message.userName[0]);
                        }
                        else if (err.response.data.message.lastName) {
                            setResponseMsg(err.response.data.message.lastName[0]);
                        }
                        else if (err.response.data.message.firstName) {
                            setResponseMsg(err.response.data.message.firstName[0]);
                        }
                        else if (err.response.data.message.accountType) {
                            setResponseMsg(err.response.data.message.accountType[0]);
                        }
                    }
                }
                else {
                    setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                }
                setResponsStatus(false);
            });
        }
        else {
            await api.put('/access/edit-user', { userName, email, firstName, lastName, initial, department, position, accountType, accountStatus, privileges })
            .then(res => {
                if(res.data.success) { 
                    setUserList(res.data.data);
                    setResponseMsg(res.data.message);
                    setResponsStatus(true);
                };
            })
            .catch(err => {
                if (err.response.status === 400) {
                    if(err.response.data.message) {
                        if(err.response.data.message.email) {
                            setResponseMsg(err.response.data.message.email[0]);
                        }
                        else if (err.response.data.message.userName) {
                            setResponseMsg(err.response.data.message.userName[0]);
                        }
                        else if (err.response.data.message.lastName) {
                            setResponseMsg(err.response.data.message.lastName[0]);
                        }
                        else if (err.response.data.message.firstName) {
                            setResponseMsg(err.response.data.message.firstName[0]);
                        }
                        else if (err.response.data.message.accountType) {
                            setResponseMsg(err.response.data.message.accountType[0]);
                        }
                        else if (err.response.data.message.accountStatus) {
                            setResponseMsg(err.response.data.message.accountStatus[0]);
                        }
                    }
                }
                else if (err.response.status === 500) {
                    setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                }
                else {
                    setResponseMsg(err.response.data.message);
                }
                setResponsStatus(false);
            });
        }
        setLoading(false);
        setShowPromtMsg(true);
    }

    const sendTemporaryPassword = async () => {
        if(!selectedUser) { return; }

        setShowPromtMsg(false);
        setLoading(true);

        await api.put('/access/temporary-password', { selectedUser })
        .then(res => {
            if(res.data.success) {
                setResponseMsg(res.data.message);
                setResponsStatus(true);
            };
        })
        .catch(err => {
            if (err && err.response && err.response.status && err.response.status === 400) {
                if(err.response.data.message) {
                    if(err.response.data.message.selectedUser) {
                        setResponseMsg(err.response.data.message.selectedUser[0]);
                    }
                }
            }
            else {
                setResponseMsg('Something went wrong, please contact CAIQTest for support.');
            }
            setResponsStatus(false);
        });
        setLoading(false);
        setShowPromtMsg(true);
    }

    useEffect(() => {
        getUserList();
    }, []);

    return (
        <>
            {/* Select Account */}
            <div className="flex items-baseline pb-6">
                <p className="flex-none px-3 text-md text-gray-700">
                    Select Account
                </p>
                <select
                    className="flex-none w-60 px-3 py-2 text-base border border-gray-150 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                    value={selectedUser || ""}
                    onChange={(e) => { getUserDetails(e.target.value); }}
                >
                    <option></option>
                    {userList && userList.map((user, i) => (
                        <option 
                            key={i} 
                            className={`${user.Active === '1' ? 'text-gray-700' : 'text-gray-400'} text-sm`}
                            value={user.id}
                        >
                            { user.Name }
                        </option>
                    ))}
                </select>
                <div className="px-3">
                    <button onClick={() => clearStateBeforeCreateUser() } type="button" className="flex-none bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none">
                        Create Account
                    </button>
                </div>
            </div>


            {/* Account Setting Form */}
            <form onSubmit={ event => event.preventDefault() }>
                <div className="shadow rounded-md">
                    <div className="p-6 py-5 bg-white">
                        {/* Form prompt */}
                        {showPromtMsg && <div className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mb-4`}>
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                                </div>
                                <div className="ml-3">
                                    <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                                </div>
                            </div>
                        </div>}

                        {/* Form fields */}
                        <div className="grid grid-cols-8 gap-6">
                            <div className="col-span-4 border-b border-gray-200">
                                <p className="pb-3">Profile:</p>
                            </div>

                            <div className="col-span-4 border-b border-gray-200">
                                <p className="pb-3">Privileges:</p>
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Username
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={userName || ""}
                                    onChange={e => { userNameInputValidation(e.target.value); }}
                                    disabled = { newUser ? "" : "disabled" }
                                />
                                {userNameErrorMsg && <p className="text-red-500 text-xs italic">{userNameErrorMsg}</p>}
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={email || ""}
                                    onChange={e => { emailInputValidation(e.target.value); }}
                                />
                                {emailErrorMsg && <p className="text-red-500 text-xs italic">{emailErrorMsg}</p>}
                            </div>

                            <div className="col-span-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Sample Submission
                                </label>
                                
                                <div className="flex w-full pt-3">
                                    <div className="flex items-center pl-4">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='Submission List'
                                            checked={(privileges && privileges.includes('Submission List')) || false }
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="comments" className="font-medium text-gray-700">
                                            Submission List
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex items-cente pl-8">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='Sample Register'
                                            checked={(privileges && privileges.includes('Sample Register')) || false }
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="candidates" className="font-medium text-gray-700">
                                                Sample Register
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex items-cente pl-8">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='Download Submission'
                                            checked={(privileges && privileges.includes('Download Submission')) || false }
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="offers" className="font-medium text-gray-700">
                                                Download Submission
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={firstName || ""}
                                    onChange={e => { firstNameInputValidation(e.target.value); }}
                                />
                                {firstNameErrorMsg && <p className="text-red-500 text-xs italic">{firstNameErrorMsg}</p>}
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Last name
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={lastName || ""}
                                    onChange={e => { lastNameInputValidation(e.target.value); }}
                                />
                                {lastNameErrorMsg && <p className="text-red-500 text-xs italic">{lastNameErrorMsg}</p>}
                            </div>

                            <div className="col-span-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Registration
                                </label>
                                
                                <div className="flex w-full pt-3">
                                    <div className="flex items-center pl-4">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='Registration List'
                                            checked={(privileges && privileges.includes('Registration List')) || false }
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="comments" className="font-medium text-gray-700">
                                                Registration List
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex items-cente pl-8">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='Test Result'
                                            checked={(privileges && privileges.includes('Test Result')) || false }
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="candidates" className="font-medium text-gray-700">
                                                Test Result
                                            </label>
                                        </div>
                                    </div>

                                    <div className="flex items-cente pl-8">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='Download Report'
                                            checked={(privileges && privileges.includes('Download Report')) || false }
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="offers" className="font-medium text-gray-700">
                                                Download Report
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Initial
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={initial || ""}
                                    onChange={e => { setInitial(e.target.value.toUpperCase()); }}
                                />
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Department
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={department || ""}
                                    onChange={e => { setDepartment(e.target.value); }}
                                />
                            </div>

                            <div className="col-span-4">
                                <label className="block text-sm font-medium text-gray-700">
                                    Settings
                                </label>
                                <div className="flex w-full pt-3">
                                    <div className="flex items-center pl-4">
                                        <input
                                            type="checkbox"
                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 rounded"
                                            value='User Account'
                                            checked={(privileges && privileges.includes('User Account')) || false}
                                            onChange={(e) => { editUserPrivileges(e.target.value) }}
                                        />
                                        <div className="ml-2 text-sm">
                                            <label htmlFor="comments" className="font-medium text-gray-700">
                                                User Account
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Position
                                </label>
                                <input
                                    type="text"
                                    className="mt-1 py-2 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    value={position || ""}
                                    onChange={e => { setPosition(e.target.value); }}
                                />
                            </div>

                            <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Account Type
                                </label>
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-150 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                                    value={accountType || ""}
                                    onChange={e => { accountTypeValidation(e.target.value); }}
                                >
                                    <option></option>
                                    <option>System Administrator</option>
                                    <option>Manager</option>
                                    <option>Sample Registration</option>
                                    <option>Finance</option>
                                    <option>Quality Control</option>
                                    <option>API</option>
                                </select>
                                {accountTypeErrorMsg && <p className="text-red-500 text-xs italic">{accountTypeErrorMsg}</p>}
                            </div>

                            <div className="col-span-4"></div>

                            {selectedUser && <div className="col-span-2">
                                <label className="block text-sm font-medium text-gray-700">
                                    Account Status
                                </label>
                                <select
                                    className="mt-1 block w-full py-2 px-3 border border-gray-150 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                                    value={ accountStatus || "" }
                                    onChange={e => { accountStatusValidation(e.target.value); }}
                                >
                                    <option></option>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </select>
                                {accountStatusErrorMsg && <p className="text-red-500 text-xs italic">{accountStatusErrorMsg}</p>}
                            </div>}

                            <div className="col-span-2 text-center">
                                <label className="block text-sm font-medium text-gray-700">
                                    &nbsp;
                                </label>
                                {selectedUser && <button
                                    type="button"
                                    className="bg-red-400 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    onClick={() => { sendTemporaryPassword() }}
                                    disabled={loading}
                                >
                                    { loading ? <div className="px-20">{getSpinnerIcon()}</div> : "Send Temporary Password" }
                                </button>}
                            </div>
                        </div>
                    </div>
                    <div className="px-6 py-3 bg-gray-100 shadow-md flex justify-between">
                        <Link to='/dashboard'
                            className="bg-purple-600 rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-purple-700 focus:outline-none"
                        >
                            Cancel
                        </Link>
                        <button
                            type="submit"
                            className="bg-indigo-600 rounded-md shadow-sm py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none"
                            onClick={() => { saveUserDetails() }}
                            disabled={loading}
                        >
                            { loading ? <div className="px-1.5">{getSpinnerIcon()}</div> : "Save" }
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AccountTab;