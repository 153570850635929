import React, { useState, useEffect } from 'react';
import api from '../../config/Api';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon } from '../../helper/SvgHelper';
//pdf.js version pdfjs-dist-2.10.377 in public folder pdf.worker.min.js

const SampleSubmissionAttachmentDialog = ({selectedRow, setSelectedRow, setShowAttachmentDialog}) => {

    const [submissionAttachments, setSubmissionAttachments] = useState(null);
    const [currentAttachmentId, setCurrentAttachmentId] = useState(null);
    const [currentAttachmentData, setCurrentAttachmentData] = useState(null);
    const [showConfirmDeleteButton, setShowConfirmDeleteButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [showResponseMsg, setShowResponseMsg] = useState(false);

    const[uploadObject, setUploadObject] = useState('');
    const[uploadFile, setUploadFile] = useState(null);
    const[uploadFileData, setUploadFileData] = useState(null);
    const[uploadFileError, setUploadFileError] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
      
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const clearAllStates = () =>{
        setShowAttachmentDialog(false)
        setLoading(false);

        setShowResponseMsg(false);
        setResponseStatus(null);
        setResponseMsg(null);

        setCurrentAttachmentId(null);
        setCurrentAttachmentData(null);
        setShowConfirmDeleteButton(false);
        setSubmissionAttachments(null);

        setUploadObject('');
        setUploadFile(null);
        setUploadFileData(null);
        setUploadFileError(null);

        setNumPages(null);
        setPageNumber(1);
        
        setSelectedRow(null);
    }

    const clearUploadFileStates = () =>{
        setUploadObject('');
        setUploadFile(null);
        setUploadFileData(null);
        setUploadFileError(null);
        setNumPages(null);
        setPageNumber(1);
    }

    const clearCurrentFileStates = () =>{
        setCurrentAttachmentId(null);
        setCurrentAttachmentData(null);
        setShowConfirmDeleteButton(false);
        setNumPages(null);
        setPageNumber(1);
    }

    const clearResponseStates = () =>{
        setShowResponseMsg(false);
        setResponseStatus(null);
        setResponseMsg(null);
    }

    const FileSizeInMb = () => {
        return (
            <div className="mt-1 -mb-1">File size: {currentAttachmentId ?  (currentAttachmentData.FileSize/1048576).toFixed(2) : (uploadFile.size/1048576).toFixed(2)} MB</div>
        )
    }

    const getSampleSubmissionAttachmentList = async (sampleSubmissionId) => {
        setLoading(true);
        if(sampleSubmissionId) {
            await api.get('/access/sample-submission-attachments', { params: { sampleSubmissionId: sampleSubmissionId } })
            .then(res => {
                if(res.data.success) { 
                    setSubmissionAttachments(res.data.data);
                }else {
                    setSubmissionAttachments(null);
                };
            })
            .catch(err => {
                console.log(err.response.data.message)
            });
        }
        setLoading(false);
    }

    const getSampleSubmissionAttachmentFile = async (sampleSubmissionId, registrationAttachmentId) => {
        setLoading(true);
        if(registrationAttachmentId) {
            await api.get('/access/sample-submission-attachment-file', { params: { sampleSubmissionId : sampleSubmissionId, registrationAttachmentId: registrationAttachmentId } })
                    .then(res => {
                        if(res.data.success) {
                            setCurrentAttachmentData(res.data.data);
                        }else {
                            setCurrentAttachmentData(null);
                        };
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                    });
        }
        setLoading(false);
    }

    const uploadSampleSubmissionAttachmentFile = async () => {
        setLoading(true);
        setShowResponseMsg(false);
        if(!uploadFileError) {
            await api.post('/access/upload-sample-submission-attachment', {  sampleSubmissionId: selectedRow.Id, uploadFileName: uploadFile.name, uploadFileType: uploadFile.type, uploadFileSize: uploadFile.size, uploadFileData: uploadFileData })
                    .then(res => {
                        if(res.data.success) { 
                            setSubmissionAttachments(res.data.data);
                            clearUploadFileStates();
                            setResponseStatus(true);
                            setResponseMsg(res.data.message);
                        };
                    })
                    .catch(err => {
                        if (err.response.status === 400) {
                            if(err.response.data.message) {
                                if(err.response.data.message.sampleSubmissionId) {
                                    setResponseMsg(err.response.data.message.sampleSubmissionId[0]);
                                }
                                else if (err.response.data.message.uploadFile) {
                                    setResponseMsg(err.response.data.message.uploadFileName[0]);
                                }
                                else if (err.response.data.message.uploadFile) {
                                    setResponseMsg(err.response.data.message.uploadFileType[0]);
                                }
                                else if (err.response.data.message.uploadFile) {
                                    setResponseMsg(err.response.data.message.uploadFileSize[0]);
                                }
                                else if (err.response.data.message.uploadFileData) {
                                    setResponseMsg(err.response.data.message.uploadFileData[0]);
                                }
                            }
                        }
                        else if (err.response.status === 500) {
                            setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                        }
                        else {
                            setResponseMsg(err.response.data.message);
                        }
                        setResponseStatus(false);
                    });
        }
        setLoading(false);
        setShowResponseMsg(true);
    }

    const deleteSampleSubmissionAttachment = async () => {
        setShowResponseMsg(false);
        setLoading(true);
        if(currentAttachmentId && currentAttachmentData) {
            await api.delete('/access/delete-sample-submission-attachment', {  params: { sampleSubmissionId : selectedRow.Id, registrationAttachmentId: currentAttachmentId } })
                    .then(res => {
                        if(res.data.success) { 
                            setSubmissionAttachments(res.data.data);
                            clearCurrentFileStates();
                            setResponseStatus(true);
                            setResponseMsg(res.data.message);
                        };
                    })
                    .catch(err => {
                        if (err.response.status === 400) {
                            if(err.response.data.message) {
                                if(err.response.data.message.sampleSubmissionId) {
                                    setResponseMsg(err.response.data.message.sampleSubmissionId[0]);
                                }
                                else if (err.response.data.message.uploadFile) {
                                    setResponseMsg(err.response.data.message.registrationAttachmentId[0]);
                                }
                            }
                        }
                        else if (err.response.status === 500) {
                            setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                        }
                        else {
                            setResponseMsg(err.response.data.message);
                        }
                        setResponseStatus(false);
                    });
        }
        setLoading(false);
        setShowConfirmDeleteButton(false);
        setShowResponseMsg(true);
    }

    const uploadFileValidation = (e) => {
        clearUploadFileStates();
        clearCurrentFileStates();
        if(e.target && e.target.files && e.target.files.length > 0) {
            let fileTypeArray = e.target.files[0].type.split('/');
            if(fileTypeArray[0].toLowerCase() !== 'image' && fileTypeArray[1].toLowerCase() !== 'pdf') {
                setUploadFileError('Please only upload image or pdf files.');
                return;
            }
            if(e.target.files[0].size > 10485760) {
                setUploadFileError('Please only upload file under 10 MB.');
                return;
            }
            setUploadFile(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setUploadFileData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            setUploadObject(e.target.value);
        }
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const PdfNavigatior = () => {
        return (
            <div className="flex mt-1 -mb-1 ml-14">
                <p>
                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </p>
                <div className="ml-4 -mt-1.5 mb-1.5">
                    <button
                        className={`${pageNumber <= 1 ? 'bg-gray-600 hover:bg-gray-700' : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3`}
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        Previous
                    </button>
                    <button
                        className={`${pageNumber >= numPages ? 'bg-gray-600 hover:bg-gray-700' : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'} px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white ml-3`}
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        Next
                    </button>
                </div>
            </div>
        )
    }

    const ResponseMessageField = () => {
        return (
            <div className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mb-4 mx-2`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                    </div>
                    <div className="ml-3">
                        <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                    </div>
                </div>
            </div>
        )
        
    }

    const UploadFileErrorMessageField = () => {
        return (
            <div className={'bg-red-50 rounded-md p-4 mb-4 mx-2'}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        {getSolidXIcon()}
                    </div>
                    <div className="ml-3">
                        <p className={'text-red-800 text-sm font-medium'}>{uploadFileError}</p>
                    </div>
                </div>
            </div>
        )
        
    }

    useEffect(() => {
        if(selectedRow) { getSampleSubmissionAttachmentList(selectedRow.Id); }
    }, []);

    return (
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-3/5 mx-auto">
                <div className="py-5 px-16 flex justify-between bg-gray-100 rounded-t-lg">
                    <div className="w-1/3"></div>
                    <div className="text-gray-900 text-center font-semibold w-1/3">
                        Sample Submission Attachment
                    </div>
                    <div className="text-gray-900 text-right text-sm w-1/3 mt-3 -mb-3">
                        Batch No.: {selectedRow.BatchNo}
                    </div>
                </div>
                <div className="flex shadow bg-white">
                    <div className="w-1/4">
                        <div className="my-4 max-h-630 h-630 overflow-y-auto">
                            { submissionAttachments && submissionAttachments.map((attachment, index) => (
                                <div
                                    onClick={() => { clearResponseStates(); clearUploadFileStates(); clearCurrentFileStates(); setCurrentAttachmentId(attachment.Id); getSampleSubmissionAttachmentFile(selectedRow.Id, attachment.Id); }}
                                    key={index}
                                    className={classNames(
                                    currentAttachmentId === attachment.Id ? 'bg-green-100 text-green-800 hover:bg-green-200' : 'text-gray-900 hover:bg-gray-100',
                                    'w-full cursor-pointer pl-8 py-2 text-sm font-medium rounded-md'
                                )}>
                                   {attachment.FileName}
                                </div>
                            ))}
                            { (!submissionAttachments || submissionAttachments.length < 1) &&
                                <div
                                    className={classNames(
                                    loading ? 'bg-blue-100 text-blue-800' : 'bg-red-100 text-red-800',
                                    'w-full text-center py-2 text-sm font-medium rounded-md'
                                )}
                                >
                                    {loading ? 'Loading ...' : 'No Data'}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="border-l flex-grow">
                        <div className="my-4 max-h-630 h-630 overflow-y-auto max-w-863 w-863 overflow-x-auto">
                            {showResponseMsg && <ResponseMessageField />}
                            {uploadFileError && <UploadFileErrorMessageField />}
                            <div className="flex justify-content my-2 ml-5">
                                {selectedRow.Status !== 'Accepted' && selectedRow.Status !== 'Cancelled' && <input value={uploadObject} type="file" name="file" accept="image/*, application/pdf" onChange={(e) => uploadFileValidation(e) } onClick={() => clearResponseStates() } />}
                                {((uploadFile && uploadFileData) || (currentAttachmentId && currentAttachmentData && currentAttachmentData.FileSize)) && <FileSizeInMb />}
                                {((uploadFile && !uploadFileError && uploadFile.type.includes('application/pdf')) || (currentAttachmentId && currentAttachmentData && currentAttachmentData.FileType.includes('application/pdf'))) && <PdfNavigatior />}
                            </div>

                            {/* File uploaded from local */}
                            { uploadFile && uploadFile.type.includes('image/') && <img className="pt-2 px-2" src={uploadFileData}></img>}
                            { uploadFile && uploadFile.type.includes('application/pdf') && 
                                <div className="px-2">
                                    <Document file={uploadFileData} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                                        <Page pageNumber={pageNumber} width={820} />
                                    </Document>
                                </div>
                            }
                            {/* File stores in the Database */}
                            { currentAttachmentId && currentAttachmentData && currentAttachmentData.FileType.includes('image/') && 
                                <img className="pt-2 px-2" src={currentAttachmentData.FileData}></img>
                            }
                            { currentAttachmentId && currentAttachmentData && currentAttachmentData.FileType.includes('application/pdf') && 
                                <div className="px-2">
                                    <Document file={currentAttachmentData.FileData} onLoadSuccess={(e) => onDocumentLoadSuccess(e)}>
                                        <Page pageNumber={pageNumber} width={820} />
                                    </Document>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ clearAllStates(); }} type="button" className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>
                    </div>
                    <div className="flex justify-between">
                        { !showConfirmDeleteButton && 
                            <>
                            {currentAttachmentData && selectedRow.Status !== 'Accepted' && selectedRow.Status !== 'Cancelled' && 
                                <div className="px-6 py-3">
                                    <button 
                                        onClick={()=>{ setShowConfirmDeleteButton(true); }} 
                                        type="button" 
                                        className="bg-red-400 border border-transparent rounded-md shadow-sm py-2 px-4 justify-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        disabled={false}
                                    >
                                        Delete
                                    </button>
                                </div>
                            }

                            {uploadFile && selectedRow.Status !== 'Accepted' && selectedRow.Status !== 'Cancelled' &&
                                <div className="px-6 py-3 text-xs font-medium text-gray-500">
                                    <button 
                                        onClick={()=>{ uploadSampleSubmissionAttachmentFile(); }} 
                                        type="button" 
                                        className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                        disabled={false}
                                    >
                                        { loading ? <div className="px-4">{getSpinnerIcon()}</div> : 'Upload' }
                                    </button>
                                </div>
                            }
                            </>
                        }

                        { showConfirmDeleteButton && selectedRow.Status !== 'Accepted' && selectedRow.Status !== 'Cancelled' &&
                            <>
                            <div className="px-6 py-3">
                                <button 
                                    onClick={()=>{ deleteSampleSubmissionAttachment(); }} 
                                    type="button" 
                                    className="bg-red-400 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    disabled={false}
                                >
                                    { loading ? <div className="px-4">{getSpinnerIcon()}</div> : 'Confirm' }
                                </button>
                            </div>

                            <div className="px-6 py-3 text-xs font-medium text-gray-500">
                                <button 
                                    onClick={()=>{ setShowConfirmDeleteButton(false); }} 
                                    type="button" 
                                    className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                                    disabled={false}
                                >
                                    Cancel
                                </button>
                            </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SampleSubmissionAttachmentDialog;