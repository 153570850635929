import React, { useState, useEffect, useRef } from 'react';
import api from '../../config/Api';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon, getCalenderIcon, getMinusIcon, getPlusIcon } from '../../helper/SvgHelper';
import EmailFormatValidator, {IntegerValidator} from '../../helper/ValidationHelper';
import DatePicker from "react-datepicker";


const SampleSubmissionForm = ({selectedRow,  setSelectedRow, setShowSubmissionFormDialog, sampleSubmissionList, setSampleSubmissionList, downloadSampleSubmissionForm}) => {

    const termAndConditionSectionRef = useRef(null);
    const sampleRequirementSectionRef = useRef(null);
    const responseMsgSectionRef = useRef(null);

    const [productCategoryTests, setProductCategoryTests] = useState(null);
    const [loading, setLoading] = useState(false);

    // Submission template
    const [submissionTemplateId, setSubmissionTemplateId] = useState(null);
    const [sampleTypeId, setSampleTypeId] = useState(null);
    const [sampleTypeList, setSampleTypeList] = useState(null);

    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [showResponseMsg, setShowResponseMsg] = useState(false);

    // Client Details
    const [companyName, setCompanyName] = useState(null);
    const [clientReference, setClientReference] = useState(null);
    const [address, setAddress] = useState(null);
    const [phone, setPhone] = useState(null);
    const [quoteNo, setQuoteNo] = useState(null);
    const [orderNo, setOrderNo] = useState(null);
    const [contactPerson, setContactPerson] = useState(['']);
    const [email, setEmail] = useState(['']);
    const [emailErrMsg, setEmailErrMsg] = useState(['']);
    const [submitter, setSubmitter] = useState(null);
    const [submitterEmail, setSubmitterEmail] = useState(null);
    const [submitterEmailErrMsg, setSubmitterEmailErrMsg] = useState(null);
    const [invoiceTo, setInvoiceTo] = useState(null);
    const [additionalComments, setAdditionalComments] = useState(null);

    // Sample Information
    const [sampleName, setSampleName] = useState(null);
    const [batchNo, setBatchNo] = useState(null);
    const [productionDate, setProductionDate] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);
    const [manufacturer, setManufacturer] = useState(null);
    const [approvalNo, setApprovalNo] = useState(null);
    const [originPlace, setOriginPlace] = useState(null);
    const [importer, setImporter] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [weight, setWeight] = useState(null);
    const [unit, setUnit] = useState(null);
    const [otherUnit, setOtherUnit] = useState(null);
    const [unitPackage, setUnitPackage] = useState(null);
    const [otherUnitPackage, setOtherUnitPackage] = useState(null);
    const [weightUnitErrMsg, setWeightUnitErrMsg] = useState('* Weight is required.');
    const [sampleColor, setSampleColor] = useState(null);
    const [sampleForm, setSampleForm] = useState(null);
    const [packingMode, setPackingMode] = useState(null);
    const [prePackagedType, setPrePackagedType] = useState(null);
    const [storageRequirement, setStorageRequirement] = useState(null);
    const [otherStorageRequirement, setOtherStorageRequirement] = useState(null);
    const [productCategory, setProductCategory] = useState(null);
    const [initialImport, setInitialImport] = useState("No");

    // Report Information
    const [noOfReport, setNoOfReport] = useState(1);
    
    // Test Information
    const [testInfo, setTestInfo] = useState([{procedRef : '', testRef : '', methodRef : '', note : ''}]);
    const [testInfoErrMsg, setTestInfoErrMsg] = useState(['']);
    const [uploadFile, setUploadFile] = useState('');
    const [uploadFileErrMsg, setUploadFileErrMsg] = useState(null);

    // T & C
    const [readTerms, setReadTerms] = useState(false);

    const ResponseMessageField = () => {
        return (
            <div ref={responseMsgSectionRef} className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mb-4`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                    </div>
                    <div className="ml-3">
                        <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                    </div>
                </div>
            </div>
        )
        
    }

    class ProductionDatePicker extends React.Component {
        render() {
            return (
                <div className="flex">
                    <button onClick={this.props.onClick} className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none">
                        {this.props.value}
                        {getCalenderIcon()}
                    </button>
                    {productionDate && <div className='mx-2 mt-3 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                        onClick={ () => { setProductionDate(null); } }
                        >
                            Remove
                    </div>}
                </div>
            );
        }
    };
    class ExpirationDatePicker extends React.Component {
        render() {
            return (
                <div className="flex">
                    <button onClick={this.props.onClick} className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none">
                        {this.props.value}
                        {getCalenderIcon()}
                    </button>
                    {expirationDate && <div className='mx-2 mt-3 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                        onClick={ () => { setExpirationDate(null); } }
                        >
                        Remove
                    </div>}
                </div>
            );
        }
    };

    const spliceFromContactPersonArray = (index) => {
        if(contactPerson && contactPerson.length === 1) { return; }

        let tempContactPersonArray = [...contactPerson];
        tempContactPersonArray.splice(index, 1);
        setContactPerson(tempContactPersonArray);
    }

    const pushIntoContactPersonArray = () => {
        setContactPerson([...contactPerson, '']);
    }

    const setInputContactPerson = (index, value) => {
        let tempContactPersonArray = [...contactPerson];
        tempContactPersonArray[index] = value;
        setContactPerson(tempContactPersonArray);
    }

    const spliceFromEmailArray = (index) => {
        if(email && email.length === 1) { return; }

        let tempEmailArray = [...email];
        let tempEmailErrMsgArray = [...emailErrMsg];
        tempEmailArray.splice(index, 1);
        tempEmailErrMsgArray.splice(index, 1);
        setEmail(tempEmailArray);
        setEmailErrMsg(tempEmailErrMsgArray);
    }

    const pushIntoEmailArray = () => {
        setEmail([...email, '']);
        setEmailErrMsg([...emailErrMsg, '']);
    }

    const setInputEmail = (index, value) => {
        const validationResult = EmailFormatValidator(value);

        let tempEmailErrMsgArray = [...emailErrMsg];
        tempEmailErrMsgArray[index] = validationResult ? '' : '* Invalid email format.'
        setEmailErrMsg(tempEmailErrMsgArray);

        let tempEmailArray = [...email];
        tempEmailArray[index] = value;
        setEmail(tempEmailArray);
    }

    const setInputSubmitterEmail = (value) => {
        setSubmitterEmail(value);
        if(value) {
            const validationResult = EmailFormatValidator(value);
            setSubmitterEmailErrMsg(validationResult ? null : '* Invalid email format.');
            return;
        }
        setSubmitterEmailErrMsg(null);
    }

    const setInputQuantity = (value) => {
        let validationResult = IntegerValidator(value);
        if(!validationResult || value < 1) {
            setQuantity(1);
            return;
        }
        setQuantity(value);
    }

    const setInputWeight = (value) => {
        if(!value || value < 0) {
            setWeight(null);
            setWeightUnitErrMsg('* Weight is required.');
            return;
        }
        if(isNaN(parseFloat(value))) {
            setWeight(null);
            setWeightUnitErrMsg('* Invalid weight.');
            return;
        }
        setWeight(value);
        if(value === '0') {
            setWeightUnitErrMsg('* Weight should be greater than zero.');
        }
        else {
            setWeightUnitErrMsg('');
        }
    }

    const setInputUnit = (value) => {
        setUnit(value);
        setOtherUnit(null);
        setWeightUnitErrMsg('');
        if(!value) {
            setWeightUnitErrMsg('* Unit is required.');
        }
        if(value === 'Other') {
            setWeightUnitErrMsg('* Please enter the unit.');
        }
        else {
            setOtherUnit(null);
        }
    }

    const setInputUnitPackage = (value) => {
        setUnitPackage(value);
        setOtherUnitPackage(null);
        setWeightUnitErrMsg('');
        if(!value) {
            setWeightUnitErrMsg('* Package is required.');
        }
        if(value === 'Other') {
            setWeightUnitErrMsg('* Please enter the package.');
        }
        else {
            setOtherUnitPackage(null);
        }
    }

    const setInputOtherUnit = (value) => {
        setOtherUnit(value);
        if(!value && weightUnitErrMsg === '') {
            setWeightUnitErrMsg('* Please enter the unit.');
            return
        }
        if(weightUnitErrMsg === '* Please enter the unit.') {
            setWeightUnitErrMsg('');
        }
    }
    
    const setInputOtherUnitPackage = (value) => {
        setOtherUnitPackage(value);
        if(!value && weightUnitErrMsg === '') {
            setWeightUnitErrMsg('* Please enter the package.');
            return
        }
        if(weightUnitErrMsg === '* Please enter the package.') {
            setWeightUnitErrMsg('');
        }
    }

    const spliceFromTestInfoArray = (index) => {
        if(testInfo && testInfo.length === 1) { return; }

        let tempTestInfoArray = [...testInfo];
        tempTestInfoArray.splice(index, 1);
        setTestInfo(tempTestInfoArray);

        let tempTestInfoErrMsgArray = [...testInfoErrMsg];
        tempTestInfoErrMsgArray.splice(index, 1);
        setTestInfoErrMsg(tempTestInfoErrMsgArray);
    }

    const pushIntoTestInfoArray = () => {
        setTestInfo([...testInfo, {procedRef : '', testRef : '', methodRef : '', note : ''}]);
        setTestInfoErrMsg([...testInfoErrMsg, '']);
    }

    const setInputTestInfo = (index, value, type) => {
        let tempTestInfoArray = [...testInfo];
        let item = productCategoryTests.findIndex(object => {
            return object.TestRef === value;
        });

        switch(type) {
            case 'test':
                if (item >=0 && tempTestInfoArray.some(t => t.testRef === productCategoryTests[item].TestRef && t.procedRef === productCategoryTests[item].ProcedRef)) {
                    let tempTestInfoErrMsg = [...testInfoErrMsg];
                    tempTestInfoErrMsg[index] = '* Test is duplicated.';
                    setTestInfoErrMsg(tempTestInfoErrMsg);
                }
                else {
                    let tempTestInfoErrMsg = [...testInfoErrMsg];
                    tempTestInfoErrMsg[index] = '';
                    setTestInfoErrMsg(tempTestInfoErrMsg);
                }

                tempTestInfoArray[index].procedRef = item >=0 ? productCategoryTests[item].ProcedRef : '';
                tempTestInfoArray[index].testRef = item >=0 ? productCategoryTests[item].TestRef : '';
                tempTestInfoArray[index].methodRef = item >=0 ? productCategoryTests[item].MethodRef : '';
                break;
            case 'note':
                tempTestInfoArray[index].note = value;
                break;
        }
        console.log(tempTestInfoArray)
        setTestInfo(tempTestInfoArray);
    }

    const getSampleSubmissionDetails = async (sampleSubmissionId) => {
        setLoading(true);
        await api.get('/access/sample-submission-details', { params: { sampleSubmissionId: sampleSubmissionId } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;

                        // Client Details
                        setCompanyName(details.clientDetails.COMPANY_NAME);
                        setClientReference(details.clientDetails.CLIENT_REF);
                        setAddress(details.clientDetails.CL_ADDRESS);
                        setPhone(details.clientDetails.CL_PHONE);
                        setQuoteNo(details.clientDetails.QUOTE);
                        setOrderNo(details.clientDetails.ORDER);
                        setContactPerson((details.clientDetails.CONTACT_PERSON && details.clientDetails.CONTACT_PERSON.split(',')) || ['']);
                        setEmail((details.clientDetails.CONTACT_EMAIL && details.clientDetails.CONTACT_EMAIL.split(',')) || ['']);
                        let tempEmailErrMsgArray = [];
                        details.clientDetails.CONTACT_EMAIL && details.clientDetails.CONTACT_EMAIL.split(',').forEach((e, i) => {
                            tempEmailErrMsgArray.push('');
                        });
                        setEmailErrMsg(tempEmailErrMsgArray.length > 0 ? tempEmailErrMsgArray : ['']); // same length to email array
                        setSubmitter(details.clientDetails.SUBMITTER);
                        setSubmitterEmail(details.clientDetails.SUB_EMAIL);
                        setInvoiceTo(details.clientDetails.INV_TO);
                        setAdditionalComments(details.clientDetails.ADD_COMMENT);

                        // Sample Information
                        setSampleName(details.sampleInfo.JOB_DESCRIPTION);
                        setBatchNo(details.sampleInfo.CLIENT_BATCH);
                        setProductionDate((details.sampleInfo.PDT_DATE && new Date(details.sampleInfo.PDT_DATE)) || null);
                        setExpirationDate((details.sampleInfo.EXPIR_DATE && new Date(details.sampleInfo.EXPIR_DATE)) || null);
                        setManufacturer(details.sampleInfo.MANUFACTURER);
                        setApprovalNo(details.sampleInfo.APPROVAL_NO);
                        setOriginPlace(details.sampleInfo.POO);
                        setImporter(details.sampleInfo.IMPOTER);
                        setQuantity(details.sampleInfo.QUANTITY || 1);
                        setWeight(details.sampleInfo.WEIGHT);
                        setUnit(details.sampleInfo.UNIT);
                        setOtherUnit(details.sampleInfo.O_UNIT);
                        setUnitPackage(details.sampleInfo.UNIT_PACKAGE);
                        setOtherUnitPackage(details.sampleInfo.OUNIT_PACKAGE);
                        setWeightUnitErrMsg('');
                        setSampleColor(details.sampleInfo.SAMPLE_COLOR);
                        setSampleForm(details.sampleInfo.SAMPLE_FORM);
                        setPackingMode(details.sampleInfo.PACK_MODE);
                        setPrePackagedType(details.sampleInfo.PREPACK_TYPE);
                        setStorageRequirement(details.sampleInfo.STOR_REQUIRE);
                        setOtherStorageRequirement(details.sampleInfo.OSTOR_REQUIRE);
                        setProductCategory(details.sampleInfo.PRDCT_CATEGORY);
                        setInitialImport(details.sampleInfo.INI_IMPORT ? details.sampleInfo.INI_IMPORT : 'No');

                        // Report Information
                        setNoOfReport(details.reportInfo.NOR || 1);

                        //Test Information
                        let tempTestInfoArray = [];
                        let tempTestInfoErrMsgArray = [];
                        details.testInfo.forEach((t, i) => {
                            tempTestInfoArray.push({procedRef : t.ProcedRef, testRef : t.TestRef,  methodRef : t.MethodRef, note : t.Note});
                            tempTestInfoErrMsgArray.push('');
                        });
                        if(tempTestInfoArray.length === 0) { tempTestInfoArray.push({procedRef : '', testRef : '',  methodRef : '', note : ''}); }
                        if(tempTestInfoErrMsgArray.length === 0) { tempTestInfoErrMsgArray.push(''); }
                        setTestInfo(tempTestInfoArray);
                        setTestInfoErrMsg(tempTestInfoErrMsgArray); // same length as testInfo array
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(err.response.data.message);
                    }
                    else {
                        setResponseMsg('Somthing went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getProductCategoryTests = async () => {
        setLoading(true);
        await api.get('/access/sample-submission-tests')
                .then(res => {
                    if(res.data.success) { 
                        setProductCategoryTests(res.data.data);
                    }else {
                        setProductCategoryTests(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(err.response.data.message);
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const submitSampleSubmission = async () => {

        let validationMsgArray = [];
        if(!companyName) {
            validationMsgArray.push('* Company Name is required.');
        }
        if(!contactPerson[0]) {
            validationMsgArray.push('* Contact Person is required.');
        }
        if(!email[0]) {
            validationMsgArray.push('* Email is required.');
        }
        if(!submitterEmail) {
            validationMsgArray.push('* Submitter Email is required.');
        }
        if(submitterEmailErrMsg) {
            validationMsgArray.push('* Invalid format for Submitter Email.');
        }
        if(!sampleName) {
            validationMsgArray.push('* Name of Sample is required.');
        }
        if(!batchNo) {
            validationMsgArray.push('* Batch No. is required.');
        }
        if(!quantity) {
            validationMsgArray.push('* Quantity is required.');
        }
        if(!weight) {
            validationMsgArray.push('* Weight is required.');
        }
        if(!unit) {
            validationMsgArray.push('* Unit is required.');
            setWeightUnitErrMsg('* Unit is required.')
        }else if(unit === 'Other') {
            if(!otherUnit) {
                validationMsgArray.push('* Please enter the unit.');
                setWeightUnitErrMsg('* Please enter the unit.')
            }
        }
        // if(!unitPackage) {
        //     validationMsgArray.push('* Package is required.');
        //     setWeightUnitErrMsg('* Package is required.')
        // }else if(unitPackage === 'Other') {
        //     if(!otherUnitPackage) {
        //         validationMsgArray.push('* Please enter the package.');
        //         setWeightUnitErrMsg('* Please enter the package.')
        //     }
        // }
        if(!packingMode) {
            validationMsgArray.push('* Modes of Packing is required.');
        }
        else if(packingMode === 'Pre-Packed') {
            if(!prePackagedType) {
                validationMsgArray.push('* Please select pre-packaged type.');
            }
        }
        if(!storageRequirement) {
            validationMsgArray.push('* Storage requirement is required.');
        }
        if(!productCategory) {
            validationMsgArray.push('* Product category is required.');
        }
        if(!testInfo[0].testRef) {
            validationMsgArray.push('* Please select a test.');
        }
        if(!readTerms) {
            validationMsgArray.push('* Please read and agree to the <Sample Requirements> and <Terms and Conditions>.');
        }

        if(validationMsgArray.length > 0) {
            setResponseStatus(false);
            setResponseMsg(validationMsgArray.toString());
            setShowResponseMsg(true);
            if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
            return;
        }

        let submissionFormData = {
            submissionId : selectedRow ? selectedRow.Id : null,
            clientDetails : {
                companyName : companyName ? companyName.toString() : null,
                clientRef: clientReference ? clientReference.toString() : null,
                address : address ? address.toString() : null,
                phone : phone ? phone.toString() : null,
                quoteNo : quoteNo ? quoteNo.toString() : null,
                orderNo : orderNo ? orderNo.toString() : null,
                contactPerson : contactPerson,
                email : email,
                submitter : submitter ? submitter.toString() : null,
                submitterEmail : submitterEmail ? submitterEmail.toString() : null,
                invoiceTo : invoiceTo ? invoiceTo.toString() : null,
                additionalComments : additionalComments ? additionalComments.toString() : null
            },
            sampleInfo : {
                sampleName : sampleName ? sampleName.toString() : null,
                batchNo : batchNo ? batchNo.toString() : null,
                productionDate : productionDate,
                expirationDate : expirationDate,
                manufacturer : manufacturer ? manufacturer.toString() : null,
                approvalNo : approvalNo ? approvalNo.toString() : null,
                originPlace : originPlace ? originPlace.toString() : null,
                importer : importer ? importer.toString() : null,
                quantity : quantity ? quantity.toString() : null,
                weight : weight ? weight.toString() : null,
                units : unit ? unit.toString() : null,
                otherUnit : otherUnit ? otherUnit.toString() : null,
                unitPackage : unitPackage ? unitPackage.toString() : null,
                otherUnitPackage : otherUnitPackage ? otherUnitPackage.toString() : null,
                sampleColor : sampleColor ? sampleColor.toString() : null,
                sampleForm : sampleForm ? sampleForm.toString() : null,
                packingMode : packingMode ? packingMode.toString() : null,
                prePackagedType : prePackagedType ? prePackagedType.toString() : null,
                storageRequirement : storageRequirement ? storageRequirement.toString() : null,
                otherStorageRequirement : otherStorageRequirement ? otherStorageRequirement.toString() : null,
                productCategory : productCategory ? productCategory.toString() : null,
                initialImport : initialImport ? initialImport.toString() : null,
            },
            reportInfo : {
                noOfReport : noOfReport.toString()
            },
            testInfo : testInfo,
            readTerms : readTerms
        }

        setLoading(true);
        setResponseMsg(null);
        setResponseStatus(null);
        setShowResponseMsg(false);
        setUploadFile('');
        if(selectedRow) {
            await api.put('/access/edit-sample-submission', { 
                    sampleSubmissionId : submissionFormData.submissionId,
                    clientDetails : submissionFormData.clientDetails, 
                    sampleInfo : submissionFormData.sampleInfo, 
                    reportInfo : submissionFormData.reportInfo,
                    testInfo : submissionFormData.testInfo,
                    readTerms : submissionFormData.readTerms
                })
                .then(res => {
                    if(res.data.success) {
                        setResponseMsg(res.data.message);
                        setResponseStatus(true);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(err.response.data.message);
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                })
                .finally(() => {
                    setShowResponseMsg(true);
                });
        }
        else {
            await api.post('/access/create-sample-submission', { 
                    clientDetails : submissionFormData.clientDetails, 
                    sampleInfo : submissionFormData.sampleInfo, 
                    reportInfo : submissionFormData.reportInfo,
                    testInfo : submissionFormData.testInfo,
                    readTerms : submissionFormData.readTerms
                })
                .then(res => {
                    if(res.data.success) { 
                        setSampleSubmissionList(res.data.data);
                        setShowSubmissionFormDialog(false);
                    };
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(err.response.data.message);
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact CAIQTest for support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                })
                .finally(() => {
                    
                });
        }
        setLoading(false);
    }

    const readUploadCsvData = (e) => {
        if(e.target && e.target.files && e.target.files.length > 0) {
            setLoading(true);
            let fileTypeArray = e.target.files[0].type.split('/');
            let fileNameArray = e.target.files[0].name.split('.');

            // Check file type
            if(fileNameArray[1].toLowerCase() !== 'csv'|| fileTypeArray[1].toLowerCase() !== 'csv') {
                setUploadFileErrMsg('* Please only upload CSV file.');
                setUploadFile('');
                return;
            }
            
            setUploadFile(e.target.value || '');

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                // const headers = reader.result.slice(0, reader.result.indexOf('\r\n')).split(',');
                // const rows = reader.result.slice(reader.result.indexOf('\r\n')+1).split('\r\n');
                const allRows = reader.result.split('\r\n');
                let testColumnIndex = null;
                let unitStr = 'µg#mg#g#kg#j#kj#°T#% of Total ß-casein#µg/kg#ng/L#ug/kg#mg/kg#CFU/g#g/100g#µg/100g#mg/100g#g/ml#MPN/100g#%#µgDFE#/100g#mg/g#ml#/25g#mg/L#% Phenol Equivalent#KJ/100g#/32.5g#µg RE/100g#';

                // Check test column index
                allRows.some((row, i) => {
                    if(testColumnIndex) {return true; }
                    row.split(',').some((field, j) => {
                        // check if test field specified
                        if(field.replace(' ', '').toLowerCase() === 'test' || field.replace(' ', '').toLowerCase() === 'tests') { 
                            return testColumnIndex = j;
                        }
                        else {
                            // check if field existence in product tests
                            productCategoryTests.some((item, index) => {
                                if(item && field && field.length > 1 && item.TestDescrip && item.TestDescrip.replace(' ', '').toLowerCase().includes(field.replace(' ', '').toLowerCase())) {
                                    return testColumnIndex = j;
                                }
                            })
                        }
                        
                    });
                });

                if(testColumnIndex === null) {
                    // set error message
                    setUploadFileErrMsg('* Cannot find test column.');
                    return;
                }
                else {
                    // start reading file
                    let tempTestInfoArray = [];
                    let tempTestInfoErrArray = [];

                    allRows.forEach((row, i) => {
                        let field = row.split(',')[testColumnIndex];
                        let found = false;

                        if(field && field.replace(' ', '').toLowerCase() != 'test' && field.replace(' ', '').toLowerCase() != 'tests') { 
                            productCategoryTests.some((item, j) => {
                                if(item && item.TestDescrip && item.TestDescrip.toLowerCase().includes(field.toLowerCase())) {
                                    if (tempTestInfoArray.some(t => t.testRef === item.TestRef && t.procedRef === item.ProcedRef)) {
                                        tempTestInfoErrArray.push('* Test is duplicated.')
                                    }else {
                                        tempTestInfoErrArray.push('');
                                    }
                                    tempTestInfoArray.push({procedRef : item.ProcedRef, testRef : item.TestRef, methodRef : item.MethodRef, note : ''});
                                    found = true;
                                    return;
                                }
                            });
                            if(!found) {
                                let fieldArray = field.split(' ');
                                if(!unitStr.toLocaleLowerCase().includes(fieldArray[fieldArray.length-1].toLowerCase())) {
                                    tempTestInfoArray.push({procedRef : '', testRef : '', methodRef : '', note : ''});
                                    tempTestInfoErrArray.push(`* Test ${field} is not available for current product category in line ${i+1}`);
                                }
                            }
                        }
                    });

                    if(tempTestInfoArray.length === 0) { tempTestInfoArray.push({procedRef : '', testRef : '', methodRef : '', note : ''}); }
                    if(tempTestInfoErrArray.length === 0) { tempTestInfoErrArray.push(''); }

                    setTestInfo(tempTestInfoArray);
                    setTestInfoErrMsg(tempTestInfoErrArray);
                    console.log(tempTestInfoArray)
                }
            });
            reader.readAsText(e.target.files[0]);
            setLoading(false);
        }
    }

    // const setInputNoOfReports = (value) => {
    //     let validationResult = IntegerValidator(value);
    //     if(!validationResult || value < 1) {
    //         setNoOfReport(1);
    //         return;
    //     }
    //     setNoOfReport(value);
    // }

    const scrollToTermsAndConditionSection = () => {
        termAndConditionSectionRef.current.scrollIntoView();
    }

    const scrollToSampleRequirementSection = () => {
        sampleRequirementSectionRef.current.scrollIntoView();
    }

    const clearAllCurrentData = () => {
        // Client Details
        setCompanyName(null);
        setClientReference(null);
        setAddress(null);
        setPhone(null);
        setQuoteNo(null);
        setOrderNo(null);
        setContactPerson(['']);
        setEmail(['']);
        setEmailErrMsg(['']);
        setSubmitter(null);
        setSubmitterEmail(null);
        setSubmitterEmailErrMsg(null);
        setInvoiceTo(null);
        setAdditionalComments(null);

        // Sample Information
        setSampleName(null);
        setBatchNo(null);
        setProductionDate(null);
        setExpirationDate(null);
        setManufacturer(null);
        setApprovalNo(null);
        setOriginPlace(null);
        setImporter(null);
        setQuantity(1);
        setWeight(null);
        setUnit(null);
        setOtherUnit(null);
        setUnitPackage(null);
        setOtherUnitPackage(null);
        setWeightUnitErrMsg('* Weight is required.');
        setSampleColor(null);
        setSampleForm(null);
        setPackingMode(null);
        setPrePackagedType(null);
        setStorageRequirement(null);
        setOtherStorageRequirement(null);
        setProductCategory(null);
        setInitialImport("No");

        // Report Information
        setNoOfReport(1);

        // Test Information
        setTestInfo([{procedRef : '', testRef : '', methodRef : '', note : ''}]);

        setSampleTypeId(null);
        setSampleTypeList(null);
    }

    const getSampleTypeList = async () => {
        setLoading(true);
        await api.get('/access/active-sample-type')
                .then(res => {
                    if(res.data.success) { 
                        setSampleTypeList(res.data.data);
                    }else {
                        setSampleTypeList(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        console.log(err.response.data.message);
                    }
                });
        setLoading(false);
    }

    const getSampleTypeDetails = async (sampleTypeId) => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/access/active-sample-type-details', { params: { sampleTypeRef: sampleTypeId } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;
                        // Reg Info
                        setSampleName(details.regInfo.sampleName);

                        //Test Info
                        setTestInfo(details.testInfo.testInfo);
                        let tempTestInfoErrMsgArray = [];
                        details.testInfo.testInfo.forEach((t, i) => { //Should not be empty array
                            tempTestInfoErrMsgArray.push('');
                        });
                        setTestInfoErrMsg(tempTestInfoErrMsgArray.length > 0 ? tempTestInfoErrMsgArray : ['']); // same length as testInfo array
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message)
                    {
                        setResponseMsg(err.response.data.message.toString());
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    useEffect(() => {
        if(selectedRow) { getSampleSubmissionDetails(selectedRow.Id); }
        else { getSampleTypeList(); }
        getProductCategoryTests();
    }, []);

    useEffect(() => {
        if(sampleTypeId) { getSampleTypeDetails(sampleTypeId); }
        else { 
            setTestInfo([{procedRef : '', testRef : '', methodRef : '', note : ''}]);
            setSampleName(null);
        }
    }, [sampleTypeId]);

    useEffect(() => {
        if(submissionTemplateId) { getSampleSubmissionDetails(submissionTemplateId); }
        else { clearAllCurrentData(); }
    }, [submissionTemplateId]);

    useEffect(() => {
        if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
    }, [responseStatus, responseMsg, showResponseMsg]);

    return (
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-3/5 mx-auto">
                <div className="py-5 px-16 flex justify-between bg-gray-100 rounded-t-lg">
                    {selectedRow && <div className="w-1/3 text-left text-sm mt-3 -mb-3">Batch No.: {selectedRow.BatchNo}</div>}
                    {!selectedRow && <div className="flex text-gray-900 text-left text-sm w-1/3 mt-3 -mb-3">
                        <label className="block text-md font-medium text-gray-700 mt-1">{!selectedRow ? 'Sample Type' : 'Limit Group'}</label>
                        <select className="ml-3 py-1 px-4 block w-2/3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={ sampleTypeId || ''}
                            onChange={e => { setSampleTypeId(e.target.value) }}
                        >
                            <option className="text-gray-400" value=''>None</option>
                            {sampleTypeList && sampleTypeList.length > 0 &&
                                sampleTypeList.map((sampleType, index) => (
                                    <option key={index} className="text-gray-800" value={sampleType.Ref}>{sampleType.Descrip}</option>
                            ))} 
                        </select>
                    </div>}
                    <div className="text-gray-900 text-center font-semibold w-1/3">
                        Sample Submission Form
                    </div>
                    {!selectedRow && <div className="flex text-gray-900 text-right text-sm w-1/3 mt-3 -mb-3">
                        <label className="block text-md font-medium text-gray-700 mt-1 ml-5">Template</label>
                        <select className="ml-3 py-1 px-4 block w-2/3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={ submissionTemplateId }
                            onChange={e => { setSubmissionTemplateId(e.target.value) }}
                        >
                            <option className="text-gray-400" value=''>None</option>
                            {sampleSubmissionList && sampleSubmissionList.length > 0 &&
                                sampleSubmissionList.map((template, index) => (
                                    <option key={index} className="text-gray-800" value={template.Id}>{template.SampleName}</option>
                                ))} 
                        </select>
                    </div>}
                    {selectedRow && <div className="w-1/3"></div>}
                </div>
                <div className="flex shadow bg-white max-h-630 h-630">
                    <div className="px-10 py-5 w-full overflow-y-auto">
                        {showResponseMsg && <ResponseMessageField />}
                        <div className="mt-2">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Client Details</div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Company Name
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={companyName || ''}
                                            onChange={e => { setCompanyName(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!companyName && <p className="text-red-500 text-xs italic mt-2">* Campany name is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Client Reference
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={clientReference || ''}
                                        onChange={e => { setClientReference(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-6 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Address
                                </label>
                                <div className="w-5/6">
                                    <input
                                        value={address || ''}
                                        onChange={e => { setAddress(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-2 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Phone
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={phone || ''}
                                        onChange={e => { setPhone(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-2 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Quote #
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={quoteNo || ''}
                                        onChange={e => { setQuoteNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-2 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Order #
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={orderNo || ''}
                                        onChange={e => { setOrderNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            {contactPerson && contactPerson.length > 0 && contactPerson.map((person, index) => (
                                <><div className="col-span-3 mx-2">
                                    {/* Contact Person */}
                                    <div className="flex" key={index}>
                                        <label className="block text-md font-medium text-gray-700 mt-1">
                                            {contactPerson.length > 1 ? `Contact Person ${index + 1}` : 'Contact Person'}
                                        </label>
                                        <div className={`mt-1 ${index > 0 ? "ml-3.5" : "ml-3"} cursor-pointer`}
                                            onClick={() => spliceFromContactPersonArray(index)}>
                                            {getMinusIcon()}
                                        </div>
                                        <div className="w-1/2">
                                            <input
                                                value={person || ""}
                                                onChange={e => { setInputContactPerson(index, e.target.value); }}
                                                type="text"
                                                className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            />
                                        </div>
                                        <div className="mt-1 ml-6 cursor-pointer"
                                            onClick={() => pushIntoContactPersonArray()}>
                                            {getPlusIcon()}
                                        </div>
                                    </div>
                                    {!contactPerson[index] && <p className="text-red-500 text-xs italic mt-2">{`* Contact person${contactPerson.length > 1 ? " " + (index+1) : ''} is required.`}</p>}
                                </div>
                                {index === contactPerson.length-1 && index%2 ===0 && <div></div>}</>
                            ))}

                            {email && email.length > 0 && email.map((e, index) => (
                                <><div className="col-span-3 mx-2" key={index}>
                                    {/* Email */}
                                    <div className="flex">
                                        <label className="block text-md font-medium text-gray-700 mt-1">
                                            {email.length > 1 ? `Email ${index + 1}` : 'Email'}
                                        </label>
                                        <div className={`mt-1 ${index > 0 ? "ml-3.5" : "ml-3"} cursor-pointer`}
                                            onClick={() => spliceFromEmailArray(index)}>
                                            {getMinusIcon()}
                                        </div>
                                        <div className="w-2/3">
                                            <input
                                                value={e || null}
                                                onChange={e => {setInputEmail(index, e.target.value)}}
                                                type="text"
                                                className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            />
                                        </div>
                                        <div className="mt-1 ml-6 cursor-pointer"
                                            onClick={() => pushIntoEmailArray()}>
                                            {getPlusIcon()}
                                        </div>
                                    </div>
                                    {(!email[index] || emailErrMsg[index] )&& <p className="text-red-500 text-xs italic mt-2">{`${!email[index] ? '* Email is required.' : emailErrMsg[index]}`}</p>}
                                </div>
                                {index === email.length-1 && index%2 ===0 && <div></div>}</>
                            ))}

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Submitter (if not client)
                                </label>
                                <div className="w-5/12">
                                    <input
                                        value={submitter || ''}
                                        onChange={e => { setSubmitter(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Submitter Email
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={submitterEmail || ''}
                                            onChange={e => { setInputSubmitterEmail(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!submitterEmail && <p className="text-red-500 text-xs italic mt-2">* Submitter email is required.</p>}
                                {submitterEmailErrMsg && <p className="text-red-500 text-xs italic mt-2">{submitterEmailErrMsg}</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Invoice To (if not client)
                                </label>
                                <div className="w-5/12">
                                    <input
                                        value={invoiceTo || ''}
                                        onChange={e => { setInvoiceTo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Additional Comments
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={additionalComments || ''}
                                        onChange={e => { setAdditionalComments(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="mt-8">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Sample's Information</div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Name of Sample
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={sampleName || ''}
                                            onChange={e => { setSampleName(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!sampleName && <p className="text-red-500 text-xs italic mt-2">* Name of sample is required.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Batch No.
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={batchNo || ''}
                                            onChange={e => { setBatchNo(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!batchNo && <p className="text-red-500 text-xs italic mt-2">* Batch number is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-2">
                                    Production Date
                                </label>
                                <div className="w-2/3">
                                    <DatePicker selected={productionDate} onChange={date => {setProductionDate(date)}} customInput={<ProductionDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-2">
                                    Expiration Date
                                </label>
                                <div className="w-2/3">
                                    <DatePicker selected={expirationDate} onChange={date => {setExpirationDate(date)}} customInput={<ExpirationDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Manufacturer
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={manufacturer || ''}
                                        onChange={e => { setManufacturer(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Approval No.
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={approvalNo || ''}
                                        onChange={e => { setApprovalNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Place of Origin
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={originPlace || ''}
                                        onChange={e => { setOriginPlace(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Importer
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={importer || ''}
                                        onChange={e => { setImporter(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Quantity
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={quantity || ''}
                                        onChange={e => setInputQuantity(e.target.value)}
                                        type="number"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Weight / Unit
                                    </label>
                                    <div className="w-1/6">
                                        <input
                                            value={weight || ''}
                                            onChange={e => { setInputWeight(e.target.value); }}
                                            type="number"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                    <div className="w-1/6">
                                        <select className="ml-4 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={unit || ''}
                                            onChange={e => { setInputUnit(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="mg">mg</option>
                                            <option value="g">g</option>
                                            <option value="kg">kg</option>
                                            <option value="ml">ml</option>
                                            <option value="L">L</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {unit === 'Other' && <div className="w-1/6">
                                        <input
                                            value={otherUnit || ''}
                                            onChange={e => { setInputOtherUnit(e.target.value); }}
                                            type="text"
                                            className="ml-5 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>}
                                    <label className="block text-md font-medium text-gray-700 mt-1 ml-5">
                                        /
                                    </label>
                                    <div className="w-1/6">
                                        <select className="ml-1 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={unitPackage || ''}
                                            onChange={e => { setInputUnitPackage(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="Bag">Bag</option>
                                            <option value="Batch">Batch</option>
                                            <option value="Bottle">Bottle</option>
                                            <option value="Can">Can</option>
                                            <option value="Packet">Packet</option>
                                            <option value="Sachet">Sachet</option>
                                            <option value="Tube">Tube</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {unitPackage === 'Other' && <div className="w-1/6">
                                        <input
                                            value={otherUnitPackage || ''}
                                            onChange={e => { setInputOtherUnitPackage(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>}
                                </div>
                                {weightUnitErrMsg && <p className="text-red-500 text-xs italic mt-2">{weightUnitErrMsg}</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Sample Color
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={sampleColor || ''}
                                        onChange={e => { setSampleColor(e.target.value) }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Sample Form
                                </label>
                                <div className="w-5/12">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={sampleForm || ''}
                                        onChange={e => { setSampleForm(e.target.value) }}>
                                        <option value=""></option>
                                        <option value="Powder">Powder</option>
                                        <option value="Liquid">Liquid</option>
                                        <option value="Solid">Solid</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Modes of Packing
                                    </label>
                                    <div className="w-1/3">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={packingMode || ''}
                                            onChange={e => { setPackingMode(e.target.value); setPrePackagedType(null); }}>
                                            <option value=""></option>
                                            <option value="Pre-Packed">Pre-Packed</option>
                                            <option value="Bulk">Bulk</option>
                                        </select>
                                    </div>
                                    {packingMode === "Pre-Packed" && <div className="w-1/4 ml-2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={prePackagedType || ''}
                                            onChange={e => { setPrePackagedType(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="Sealed">Sealed</option>
                                            <option value="Unsealed">Unsealed</option>
                                        </select>
                                    </div>}
                                </div>
                                {!packingMode && <p className="text-red-500 text-xs italic mt-2">* Modes of Packing is required.</p>}
                                {(packingMode === "Pre-Packed" && !prePackagedType) && <p className="text-red-500 text-xs italic mt-2">* Please select pre-packaged type.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Storage Requirement
                                    </label>
                                    <div className="w-1/2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={storageRequirement || ''}
                                            onChange={e => { setStorageRequirement(e.target.value); setOtherStorageRequirement(null); }}>
                                            <option value=""></option>
                                            <option value="Room Temperature">Room Temperature</option>
                                            <option value="Cold Storage">Cold Storage</option>
                                            <option value="Freeze">Freeze</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                    {storageRequirement === "Other" && <div className="w-1/4 ml-2">
                                        <input
                                            value={otherStorageRequirement || ''}
                                            onChange={e => { setOtherStorageRequirement(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>}
                                </div>
                                {!storageRequirement && <p className="text-red-500 text-xs italic mt-2">* Storage requirement is required.</p>}
                                {(storageRequirement === "Other" && !otherStorageRequirement) && <p className="text-red-500 text-xs italic mt-2">* Please enter other storeage requirement.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Product Category
                                    </label>
                                    <div className="w-1/2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={productCategory || ''}
                                            onChange={e => { setProductCategory(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="1">Infant Formula Stage 1</option>
                                            <option value="2">Infant Formula Stage 2</option>
                                            <option value="3">Infant Formula Stage 3</option>
                                            <option value="4">Infant Formula Child</option>
                                            <option value="5">Milk Power</option>
                                            <option value="6">Liquid Milk</option>
                                            <option value="7">Whey Powder</option>
                                            <option value="8">Cheese</option>
                                            <option value="9">Honey</option>
                                            <option value="10">Yoghurt</option>
                                            <option value="11">Cream</option>
                                            <option value="12">Base Powder</option>
                                            <option value="13">Pet Food</option>
                                            <option value="14">UHT Milk</option>
                                            <option value="0">Other</option>
                                        </select>
                                    </div>
                                </div>
                                {!productCategory && <p className="text-red-500 text-xs italic mt-2">* Product category is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Initial Import
                                </label>
                                <div className="w-5/12">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={initialImport || ''}
                                        onChange={e => { setInitialImport(e.target.value) }}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Test information */}
                        <div className="mt-8 flex">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Test Information</div>
                            <div className='mx-2 mt-3 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                onClick={ () => { setTestInfo([{procedRef : '',  testRef : '', methodRef : '', note : ''}]); setTestInfoErrMsg(['']); } }
                                >Clear all tests
                            </div>
                            <div className='ml-5 mt-2 text-sm'>
                                { loading ? 
                                    getSpinnerIcon()
                                    : 
                                    <input
                                        type="file"
                                        accept=".csv"
                                        value={uploadFile || ''}
                                        onChange={(e) => readUploadCsvData(e) } 
                                        onClick={() => { setUploadFile(''); setUploadFileErrMsg(null);} }
                                        disabled={!productCategory} />
                                }
                            </div>
                            {uploadFileErrMsg && <p className="text-red-500 text-xs italic mt-3">{uploadFileErrMsg}</p>}
                        </div>
                        {testInfo && testInfo.length > 0 &&
                            testInfo.map((item, i) => (
                                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" key={i}>
                                    <div className="col-span-6 mx-2">
                                        <div className="flex">
                                            <div className="mt-1 cursor-pointer"
                                                onClick={() => pushIntoTestInfoArray() }>
                                                {getPlusIcon()}
                                            </div>
                                            <div className="w-2/3 mx-2">
                                                <select className={`${!item.testRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                    value={item.testRef || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'test') }}>
                                                    <option className="text-gray-400" value="">Test Item</option>
                                                    {productCategoryTests && productCategoryTests.length > 0 &&
                                                        productCategoryTests.map((obj, index) => (
                                                            <option key={index} className="text-gray-800" value={obj.TestRef}>{obj.TestDescrip}</option>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="w-2/3 mx-2">
                                                <select className={`${!item.methodRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                    value={item.methodRef || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'method') }}
                                                    //disabled={!item.testRef}>
                                                    disabled={true}>
                                                    <option className="text-gray-300" value="">Test Method</option>
                                                    {productCategoryTests && productCategoryTests.length > 0 &&
                                                        productCategoryTests.map((obj, index) => (
                                                            obj.TestRef === item.testRef ?
                                                            <option key={index} className="text-gray-800" value={obj.MethodRef}>{obj.MethodDescrip}</option>
                                                            :
                                                            <></>
                                                        ))}
                                                </select>
                                            </div>
                                            <div className="w-2/3 mx-2">
                                                <input
                                                    value={item.note || ''}
                                                    onChange={e => { setInputTestInfo(i, e.target.value, 'note') }}
                                                    type="text"
                                                    placeholder="Additional Notes"
                                                    className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                />
                                            </div>
                                            <div className="ml-4 mt-1 cursor-pointer"
                                                onClick={() => spliceFromTestInfoArray(i) }>
                                                {getMinusIcon()}
                                            </div>
                                        </div>
                                        {!testInfo[i].testRef && <p className="text-red-500 text-xs italic mt-2">* Please select a test.</p>}
                                        {testInfoErrMsg[i] && <p className="text-red-500 text-xs italic mt-2">{ testInfoErrMsg[i] }</p>}
                                    </div>
                                </div>))
                        }

                        {/* <div className="mt-8">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Report Information</div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="flex col-span-4 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    No. of Report(s) Required
                                </label>
                                <div className="w-1/5">
                                    <input
                                        value={noOfReport || 1}
                                        onChange={e => { setInputNoOfReports(e.target.value) }}
                                        type="number"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>
                        </div> */}

                        <div ref={sampleRequirementSectionRef} className="mt-12">
                            <div className='w-1/3 border-gray-400 text-gray-600 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Sample Requirements</div>
                        </div>
                        <div className="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="flex col-span-6 mx-2">
                                <div className="block text-md font-medium text-gray-600">
                                    1.   Sampling quantities to meet inspection requirements.
                                    Infant Formula and Milk Powers: For products with retail packaging &gt;= 400g (mL), randomly sample at least 6 retail packages. For products with retail packaging less than 400g (mL), randomly sample at least 6 units where 1 unit should be no less than 800g. (e.g. if the retail package is 200g, 4 packages are 1 unit).
                                    Liquid Milk: For products with retail packaging &gt;= 0.75 L, randomly sample at least 4 complete packages. For products with retail packaging less than 0.75 L, randomly sample at least 8 retail packages.
                                    Whey Power: For products with retail packaging &gt;= 400g, randomly sample at least 6 retail packages. For products with retail packaging less than 400g, randomly sample at least 6 units where 1 unit should be no less than 800g. (e.g. if the retail package is 200g, 4 packages are 1 unit).
                                    Others (butter, cream, etc.): For products with retail packaging &gt;=400 g, randomly sample at least 6 retail packages. For products with retail packaging less than 400g, randomly sample at least 6 units where 1 unit should be no less than 800g (e.g. if the retail package is 200g, 4 packages are 1 unit).
                                    * If any microbiological test involved, please send an extra individual packaging sample. (For Five points microbiology test please send five individual packaging samples.)
                                    <br/>
                                    2.   Guarantee the sample is the same batch with customs clearance.
                                </div>
                            </div>
                        </div>

                        <div ref={termAndConditionSectionRef} className="mt-8">
                            <div className='w-1/3 border-gray-400 text-gray-600 text-lg leading-6 font-medium py-2 px-1 border-b-2'>Terms and Conditions</div>
                        </div>
                        <div className="mt-3 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="flex col-span-6 mx-2">
                                <div className="block text-md font-medium text-gray-600">
                                    1. The  customer  is  responsible  for  the  accuracy  of  sample  information  when  submitting  samples  for  testing  at CAIQTEST(Pacific). CAIQTEST(Pacific) are only responsible for the testing results as they relate to the samples received.
                                    <br/>2. If no test method is selected, the laboratory will choose the default testing for the sample type received. CAIQTEST(Pacific) will not make interpretations or offer opinions on test results. Any comment made against a test result is for reference only. CAIQTEST(Pacific) takes no responsibility for questions arising due to comments against test results.
                                    <br/>3. CAIQTEST(Pacific) complies with relevant laws and regulations. The laboratory will ensure the impartiality, accuracy and authenticity of the test results. All client information and testing data will be kept strictly confidential. Details of Quotations and testing applications will not be disclosed to any third party without written permission, except where required by law, judicial or administrative regulatory requirements.
                                    <br/>4. If there are any changes to the testing or the time frame of contracted work, either the “Application for Amendment” (where changes are requested by the client) or the “Executive Deviation Record” (where CAIQTEST(Pacific) makes changes) will be filled out and must be mutually agreed on.
                                    <br/>5. If there are any objections or queries relating to the test results they must be received within 15 days from receipt of the Test Report. Where objections or queries are received outside the 15 days period they will be deemed as no objection. If changes to the test report must be made due to error on CAIQTEST(Pacific)’s part, the laboratory will cover the cost of re-issue. If the need to change the test report is the result of client error or request, the client shall submit an “Application for Amendment” and wait for approval from CAIQTEST(Pacific) for the change. The client will be responsible for fees and charges incurred in changing the test report. If a report is changed and re-issued, the original must be returned.
                                    <br/>6. Test reports cannot be used for advertising purposes, court evidence, arbitration or other relevant activities unless approval from CAIQTEST(Pacific) has been given.
                                    <br/>7. Reports cannot be modified by clients after issue. If modifications are required which do not violate the relevant provisions, the client must fill in the “Report Change Request” form. The change request form must be received within 60 days of the report issue date, and if the change is accepted, the client is responsible for paying any incurred cost. After 60 days CAIQTEST(Pacific) will not accept requests to modify reports.
                                    <br/>8. If inaccuracy in the test report is the result of fault or negligence on the part of CAIQTEST(Pacific) and causes direct economic losses, CAIQTEST(Pacific) will provide compensation, where compensation does not exceed twice the cost of detection.  Where  indirect  economic  losses,  mental  damage,  unforeseen  losses  and  third  parties’  losses  occur, CAIQTEST(Pacific) will not assume liability.
                                    <br/>9. CAIQTEST(Pacific) will not take responsibility or provide compensation for damage to the integrity of the test sample or loss/damage  to  the  report  resulting  from  Force  Majeure,  natural  disasters,  national  laws/policies  or  administrative enforcement action.
                                    <br/>10. The Application Agreement and Quotation will take effect immediately upon signing of this form by the client or their authorized representative. Invalidation for any reason of any individual provision does not affect the validity of the other provisions laid out in this Agreement.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ setShowSubmissionFormDialog(false); setSelectedRow(null); }} type="button" className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>      
                    </div>

                    <div className="px-6 py-3 text-gray-700 text-sm font-medium mt-2 flex">
                        <input value={readTerms} onChange={()=>{ setReadTerms(!readTerms) }} className="mr-2 mb-2 cursor-pointer" type="checkbox"/>I agree to the <div onClick={ ()=>{ scrollToSampleRequirementSection()} }className='mx-1 cursor-pointer hover:underline'>&lt;Sample Requirements&gt;</div> and <div onClick={ ()=>{ scrollToTermsAndConditionSection()} } className='mx-1 cursor-pointer hover:underline'>&lt;Terms and Conditions&gt;</div>
                    </div>

                    <div className="flex justify-between">
                        {selectedRow && <div className="px-6 py-3">
                            <button 
                                onClick={()=>{ downloadSampleSubmissionForm(selectedRow); }} 
                                type="button" 
                                className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                disabled={loading}
                            >
                                { loading ? <div className="px-6">{getSpinnerIcon()}</div> : 'Download' }
                            </button>
                        </div>}

                        {((selectedRow && selectedRow.Status !== "Accepted" && selectedRow.Status !== "Cancelled") || !selectedRow) &&
                            <div className="px-6 py-3 text-xs font-medium text-gray-500">
                                <button 
                                    onClick={()=>{ submitSampleSubmission(); }} 
                                    type="button" 
                                    className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                    disabled={loading}
                                >
                                    { loading ? <div className="px-3">{getSpinnerIcon()}</div> : 'Submit' }
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SampleSubmissionForm;